import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import "./PatientProfile.css";
import Header from "../../front-end-global-components/components/Header/Header";
import { useParams } from "react-router";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import { isValidArray, isValidObject } from "../../services/validators";
// import defaultUserImage from "../../assets/icons/default_user.svg";
import followingPatientIcon from "../../assets/icons/Following_patient.svg";
import followPatientIcon from "../../assets/icons/Follow_patient.svg";
import PatientTimeline from "../../front-end-global-components/components/PatientTimeline/PatientTimeline";
import "../../front-end-global-components/design-system.css";
import ReArrangeAndAddPrescription from "../../front-end-global-components/components/ReArrangeAndAddPrescription/ReArrangeAndAddPrescription";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import Button from "../../front-end-global-components/components/Button/Button";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import fallback from "../../assets/images/fallback.svg";
import { validDocumentType } from "../../utils/constants";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import {
  TickIcon,
  PinIcon
} from "../../front-end-global-components/assets/assets";
import { setErrorStatus } from "../../redux/status/actions";
import {
  checkIfPatientConnectedToNinto,
  findPatient,
  followPatient,
  checkConsentRequestPresent,
  isSubscriptionRequestPresent,
  sendConsentRequest,
  sendSubscriptionRequest,
  unFollowPatient,
  unPinPatient
} from "../../redux/abdmPatients/actions";
import {
  getDocumentDownloadUrl,
  getUploaderImageDownloadUrl
} from "../../redux/downloads/actions";
import {
  addDocumentFilter,
  getDataTransferDetails,
  getDocumentsData,
  getUpdatedDocuments,
  removeDocumentFilter,
  retryDataTransfer
} from "../../redux/documents/actions";

function PatientProfile(props) {
  let { patientId } = useParams();
  const fileInputFieldRef = useRef(null);
  const [src, setSrc] = useState([]);
  const [file, setFile] = useState("");
  const cropper = useRef(null);
  const [cropperReady, setCropperReady] = useState(false);
  const [consentRequestStatus, setConsentRequestStatus] = useState(null);
  const [subscriptionRequestStatus, setSubscriptionRequestStatus] =
    useState(null);
  const [profileData, setProfileData] = useState({});
  const [isDataTransferRequested, setIsDataTransferRequested] = useState(true);
  const [showRetryButton, setShowRetryButton] = useState(false);
  const [showDateFormModal, setShowDateFormModal] = useState(false);
  const [dateRange, setDateRange] = useState("");
  const [scrollTopPosition, setScrollTopPosition] = useState();

  useEffect(() => {
    if (!patientId) {
      return;
    }
    if (
      props.abdmPatients.consentRequest &&
      props.abdmPatients.consentRequest[patientId]
    ) {
      setConsentRequestStatus(
        props.abdmPatients.consentRequest?.[patientId][
          Object.keys(props.abdmPatients.consentRequest[patientId])[0]
        ]?.status
      );
    } else {
      setConsentRequestStatus(null);
    }
    // eslint-disable-next-line
  }, [patientId, props.abdmPatients.consentRequest]);

  useEffect(
    () => {
      if (
        patientId &&
        profileData?.permanent === true &&
        props.abdmPatients?.subscriptionRequest &&
        props.abdmPatients?.subscriptionRequest?.[patientId]
      ) {
        setSubscriptionRequestStatus(
          props.abdmPatients?.subscriptionRequest?.[patientId]?.[
            Object.keys(
              props.abdmPatients?.subscriptionRequest?.[patientId]
            )?.[0]
          ]?.status
        );
      } else {
        setSubscriptionRequestStatus(null);
      }
    },
    // eslint-disable-next-line
    [patientId, props.abdmPatients.subscriptionRequest]
  );

  useEffect(() => {
    let find = false;

    !find &&
      props.abdmPatients.following &&
      Object.keys(props.abdmPatients.following).forEach((id) => {
        const data = props.abdmPatients.following[id];
        if (id === patientId) {
          find = true;
          setProfileData({
            ...profileData,
            fullName: data.patient.fullName,
            phoneNumber: data.patient.phoneNumber,
            healthId: data.patient.healthId,
            yob: data.patient.yob ? data.patient.yob : "",
            gender: data.gender ? data.gender : "",
            patientId: data.patient.patientId,
            permanent: data.permanent
          });
        }
      });

    !find &&
      props.abdmPatients.pinned &&
      Object.keys(props.abdmPatients.pinned).forEach((id) => {
        const data = props.abdmPatients.pinned[id];
        if (id === patientId) {
          find = true;
          setProfileData({
            ...profileData,
            fullName: data.patient.fullName,
            phoneNumber: data.patient.phoneNumber,
            healthId: data.patient.healthId,
            yob: data.patient.yob ? data.patient.yob : "",
            gender: data.gender ? data.gender : "",
            patientId: data.patient.patientId,
            permanent: data.permanent
          });
        }
      });

    !find &&
      props.abdmPatients.searchResults &&
      Object.keys(props.abdmPatients.searchResults).forEach((id) => {
        const data = props.abdmPatients.searchResults[id];
        if (data.patientId === patientId) {
          find = true;
          setProfileData({
            ...profileData,
            fullName: data.name,
            phoneNumber: data.phoneNumber,
            healthId: data.healthId,
            yob: data.yob,
            gender: data.gender ? data.gender : "",
            patientId: data.patientId
          });
        }
      });

    !find && props.findPatient(patientId, "patientId");

    // eslint-disable-next-line
  }, [
    patientId,
    props.abdmPatients.following,
    props.abdmPatients.pinned,
    props.abdmPatients.searchResults
  ]);

  useEffect(() => {
    if (patientId && isValidObject(profileData)) {
      props.checkConsentRequestPresent(profileData.healthId, patientId, true);
      if (props.abdmPatients.following[patientId])
        props.isSubscriptionRequestPresent(
          profileData.healthId,
          patientId,
          true
        );
    }
    // eslint-disable-next-line
  }, [profileData]);

  useEffect(() => {
    const timeOutSubscriber = setInterval(() => {
      if (
        subscriptionRequestStatus !== null &&
        subscriptionRequestStatus === "SENT"
      ) {
        props.isSubscriptionRequestPresent(profileData.healthId, patientId);
      }
    }, 5000);

    return () => {
      clearInterval(timeOutSubscriber);
    };

    // eslint-disable-next-line
  }, [subscriptionRequestStatus]);

  useEffect(() => {
    const timeOutSubscriber = setInterval(() => {
      if (
        (consentRequestStatus !== null && consentRequestStatus === "SENT") ||
        (consentRequestStatus !== "GRANTED" &&
          subscriptionRequestStatus === "GRANTED")
      ) {
        props.checkConsentRequestPresent(profileData.healthId, patientId);
      }
    }, 5000);
    if (
      (consentRequestStatus !== null && consentRequestStatus === "GRANTED") ||
      (consentRequestStatus !== "GRANTED" &&
        subscriptionRequestStatus === "GRANTED")
    ) {
      props.getDataTransferDetails(patientId);
      props.getDocumentsData(patientId);
    }

    return () => {
      clearInterval(timeOutSubscriber);
    };

    // eslint-disable-next-line
  }, [consentRequestStatus, subscriptionRequestStatus]);

  useEffect(() => {
    const timeOutSubscriber = setInterval(() => {
      if (
        props.documents.dataTransfer &&
        isValidArray(props.documents.dataTransfer[patientId])
      ) {
        const isGettingDataInComplete = props.documents?.dataTransfer?.[
          patientId
        ]?.some((data) => data.status === "REQUESTED");

        if (isGettingDataInComplete) {
          setIsDataTransferRequested(true);
          props.getDataTransferDetails(patientId);
          props.getDocumentsData(patientId);
        } else {
          setIsDataTransferRequested(false);
        }
      }
      if (props.documents?.dataTransfer?.[patientId]) {
        const showRetryButton = Object.values(
          props.documents?.dataTransfer?.[patientId]
        ).every((data) => +new Date() - data.timestamp > 600000);

        if (showRetryButton === true) {
          setShowRetryButton(true);
        }
      }
    }, 5000);

    return () => {
      clearInterval(timeOutSubscriber);
    };

    // eslint-disable-next-line
  }, [props.documents.dataTransfer]);

  useEffect(() => {
    if (file) {
      setCropperReady(false);
      let canvas = document.getElementById("imageData");
      let ctx = canvas.getContext("2d");
      let maxW = 1024;
      let maxH = 1024;
      let img = new Image();
      img.onload = function () {
        let iw = img.width;
        let ih = img.height;
        let scale = Math.min(maxW / iw, maxH / ih);
        let iwScaled = iw * scale;
        let ihScaled = ih * scale;
        canvas.width = iwScaled;
        canvas.height = ihScaled;
        ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
        cropper.current = new Cropper(canvas, {
          viewMode: 2,
          maxContainerWidth: 500,
          maxCanvasWidth: 100,
          maxCropBoxWidth: 20,
          background: false,
          borderRadius: 50,
          zoomable: true,
          dragMode: "move",
          responsive: true,
          guides: false,
          rotatable: true
        });
        setCropperReady(true);
      };
      img.src = file;
    }
  }, [file]);

  function cropHandler() {
    cropper.current.getCroppedCanvas().toBlob(
      (blob) => {
        //for blob to image
        const file = new File([blob], `${props.fileName - +new Date()}.png`, {
          type: "image/png"
        });
        setSrc([...src, file]);
      },
      "image/png",
      1
    );
    setFile("");
  }

  function fileHandler(event) {
    if (
      event.target.files[0] &&
      (event.target.files[0].type === "image/jpeg" ||
        event.target.files[0].type === "image/png")
    ) {
      setFile(URL.createObjectURL(event.target.files[0]));
    } else if (isValidArray(src)) {
      return setErrorStatus({
        code: "custom",
        message: "Kindly upload a valid File"
      });
    } else if (event.target.files[0].type === "application/pdf") {
      props.addPrescription(event.target.files[0], null);
    } else {
      return setErrorStatus({
        code: "custom",
        message: "Kindly upload a valid File"
      });
    }
  }

  function handleClose() {
    setFile("");
  }

  const isValidPatientProfile = () => {
    return (
      typeof patientId === "string" &&
      typeof props.clinics.currentClinic === "string" &&
      props.clinics.currentClinic.length > 0 &&
      isValidObject(profileData) &&
      profileData.patientId === patientId
    );
  };

  return (
    <>
      {file && file !== null ? (
        <>
          <div
            data-cy="cropper-modal"
            className="inherit-parent-height inherit-parent-width flex-center-children-horizontally flex-direction-column background-color-black"
          >
            <div className="inherit-parent-height inherit-parent-width max-height-90-percentage padding-top-default">
              <canvas
                className="display-block max-width-100-percentage"
                id="imageData"
                data-cy="cropper-canvas"
              ></canvas>
            </div>
            <footer className="padding-larger inherit-parent-width flex-align-items-center flex-justify-content-space-between">
              <button
                data-cy="crop-img-cancel-button"
                type="submit"
                variant="secondary"
                className="background-transparent font-color-white font-family-gilroy-medium font-size-medium"
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </button>
              <button
                data-cy="crop-img-rotate-button"
                type="button"
                className="background-transparent font-color-white  font-family-gilroy-medium font-size-medium"
                variant="secondary"
                onClick={() => {
                  cropper.current.rotate(90);
                  cropper.current.zoomTo(0);
                }}
              >
                Rotate
              </button>
              <button
                data-cy="crop-img-save-button"
                type="button"
                className="background-transparent font-color-white font-family-gilroy-medium font-size-medium"
                disabled={!cropperReady}
                onClick={(event) => {
                  if (
                    cropper.current.getData(true).width <= 50 ||
                    cropper.current.getData(true).height <= 50
                  ) {
                    return;
                  }
                  cropHandler(event);
                }}
              >
                Save
              </button>
            </footer>
          </div>
        </>
      ) : (
        <div className="inherit-parent-height">
          <Header
            title={isValidArray(src) ? "Add prescription" : "Patient Profile"}
            backButtonOnClick={() => {
              if (isValidArray(src)) {
                setSrc([]);
              } else {
                props.navigate(-1);
              }
            }}
            supportIconOnClick={() => {
              props.navigate("/queries");
            }}
          />

          <div
            className="remaining-body-height overflow-x-scroll"
            onScroll={(event) => {
              setScrollTopPosition(Math.round(event.target.scrollTop));
            }}
          >
            {isValidArray(src) ? (
              <ReArrangeAndAddPrescription
                src={src}
                setSrc={setSrc}
                onAddExtraImageClick={() => {
                  fileInputFieldRef.current.click();
                }}
                fileName={`${patientId}-${+new Date()}`}
                onAddPrescriptionClick={(arrayList) => {
                  props.addPrescription(arrayList, null);
                  setSrc([]);
                }}
                isLoading={props.documents.loading}
              />
            ) : (
              <>
                {props.abdmPatients.loading === false &&
                ((profileData && typeof profileData?.healthId === "string") ||
                  (patientId && typeof profileData?.name === "string")) ? (
                  <PatientInfoCard
                    {...props}
                    profileData={profileData}
                    patientId={patientId}
                    patientHealthId={profileData.healthId}
                    patientName={profileData.fullName}
                  />
                ) : (
                  <PatientCardSuspense />
                )}

                {((props.abdmPatients.following[patientId] &&
                  subscriptionRequestStatus === "GRANTED" &&
                  consentRequestStatus !== "GRANTED") ||
                  (!props.abdmPatients.following[patientId] &&
                    consentRequestStatus !== "GRANTED")) && (
                  <ConsentRequestRequired
                    isRequestExists={
                      consentRequestStatus === "SENT" ? true : false
                    }
                    loading={props.abdmPatients.loading}
                    sendConsentRequestOnClick={(expiryMonth) => {
                      props.sendConsentRequest({
                        expiryMonth: expiryMonth,
                        healthId: profileData.healthId,
                        patientId: patientId
                      });
                    }}
                  />
                )}
                {props.abdmPatients.following[patientId] &&
                  subscriptionRequestStatus !== "GRANTED" && (
                    <SubscriptionRequired
                      isRequestExists={
                        subscriptionRequestStatus === "SENT" ? true : false
                      }
                      loading={props.abdmPatients.loading}
                      sendSubscriptionRequestOnClick={(expiryMonth) => {
                        props.sendSubscriptionRequest(
                          expiryMonth,
                          profileData.healthId,
                          patientId
                        );
                      }}
                    />
                  )}
                {((props.abdmPatients.following[patientId] &&
                  consentRequestStatus === "GRANTED" &&
                  subscriptionRequestStatus === "GRANTED") ||
                  (!props.abdmPatients.following[patientId] &&
                    consentRequestStatus === "GRANTED")) && (
                  <PatientTimeline
                    // paginationLoading={props.documents.paginationLoading}
                    profilePictureSize={"small"}
                    patientName={profileData.fullName}
                    removeScrollBar={true}
                    dataTransfer={
                      props.documents?.dataTransfer?.[patientId] &&
                      Object.values(props.documents?.dataTransfer?.[patientId])
                    }
                    transferStatus={isDataTransferRequested}
                    lastUpdatedStatus={isDataTransferRequested}
                    showRetryButton={
                      (props.documents?.dataTransfer?.[patientId] &&
                        !Object.values(
                          props.documents?.dataTransfer?.[patientId]
                        )) ||
                      showRetryButton
                    }
                    retryButtonOnClick={() => {
                      props.retryDataTransfer({ patientId: patientId });
                    }}
                    retryButtonLoading={props.documents.loading}
                    overflowHidden={scrollTopPosition >= 226 ? false : true}
                    documentFilterMenu={[
                      // "symptom",
                      "prescription",
                      "report",
                      "admission",
                      "vital",
                      "vaccine"
                      // "form"
                    ]}
                    key="patient-time-line"
                    loading={
                      props.profile.loading ||
                      props.abdmPatients.loading ||
                      !isValidPatientProfile()
                    }
                    consentsEnds={true}
                    patientAccessOnClick={() => {
                      setShowDateFormModal(true);
                    }}
                    downloads={props.downloads.data}
                    documents={
                      props.documents?.data &&
                      Object.values(props.documents?.data)?.filter(
                        (data) => data.patient?.patientId === patientId
                      )
                    }
                    documentCardOnClick={(
                      documentType,
                      documentId,
                      consentId
                    ) => {
                      if (documentType === "vitals") {
                        return;
                      } else if (documentType === "symptom") {
                        props.navigate(`/symptoms/${documentId}`);
                      } else if (consentId) {
                        props.retryDataTransfer({
                          consentId: consentId,
                          patientId: patientId
                        });
                      } else {
                        props.navigate(
                          `/${validDocumentType(documentType)}/${documentId}`
                        );
                      }
                    }}
                    hideUploadButton={true}
                    getAudioUrl={(documentId) =>
                      props.getDocumentDownloadUrl("symptoms", documentId)
                    }
                    getDocumentsCardProfilePicture={(type, userId) => () => {}}
                    profilePictures={
                      props.downloads.data
                        ? props.downloads.data.profilePictures
                        : null
                    }
                    allowedUploadDocuments={[
                      "vitals",
                      "createPrescription",
                      "uploadPrescription"
                    ]}
                    onNewDocumentCreate={(type) => {
                      if (type === "createPrescription") {
                        props.navigate(`/addPrescription`);
                      } else if (type === "uploadPrescription") {
                        fileInputFieldRef.current.click();
                      } else if (type === "vitals") {
                        props.navigate("/addvitals");
                      }
                    }}
                    viewerId={
                      isValidObject(props.profile.data)
                        ? Object.keys(props.profile.data)[0]
                        : ""
                    }
                    // patientName={patientName()}
                    viewerType="doctor"
                    filterState={props.documents.filters}
                    onFilterClick={(type, filterValue) => {
                      props.addDocumentFilter(type, filterValue);
                    }}
                    onClearFilterClick={() => {
                      props.removeDocumentFilter("doctorAndClinic");
                    }}
                  />
                )}
                <Modal
                  onClose={() => {
                    setShowDateFormModal(!showDateFormModal);
                    setDateRange(null);
                  }}
                  canIgnore={true}
                  show={showDateFormModal}
                  background="false"
                  boxShadow="false"
                  borderRadius="false"
                  width={"width-30-percent"}
                  // background="background-transparent"
                  // height="height-fit-to-content"
                >
                  <form
                    onSubmit={(event) => {
                      event.preventDefault();
                      if (dateRange) {
                        props.sendConsentRequest({
                          fromDate: new Date(dateRange).toISOString(),
                          expiryMonth: 6,
                          healthId: profileData.healthId,
                          patientId: patientId
                        });
                        setShowDateFormModal(!showDateFormModal);
                      }
                    }}
                  >
                    <div
                      className={`${"border-radius-default"} background-white inherit-parent-width padding-larger font-family-gilroy-medium`}
                    >
                      <InputBox
                        type="date"
                        label="dating From"
                        name="datingFrom"
                        required
                        maxDate={
                          isValidObject(
                            props.abdmPatients?.consentRequest?.[patientId]
                          ) &&
                          Object.values(
                            props.abdmPatients?.consentRequest?.[patientId]
                          )[0].fromTime.split("T")[0]
                        }
                        onChange={(event) => {
                          setDateRange(+new Date(new Date(event.target.value)));
                        }}
                      />

                      <div className="flex-justify-content-center margin-top-larger">
                        <div className="inherit-parent-width  padding-right-default flex-justify-content-center font-family-gilroy-bold">
                          <Button
                            type="submit"
                            boxShadow={false}
                            className="max-width-150px max-height-50px"
                            data-cy="Send-request-button"
                            text="Send request"
                            loading={props.abdmPatients.loading}
                            disabled={dateRange === null ? true : false}
                          />
                        </div>
                        <div className="inherit-parent-width padding-left-default flex-justify-content-center font-family-gilroy-medium ">
                          <Button
                            type="submit"
                            boxShadow={false}
                            className="max-width-150px max-height-50px"
                            text="Cancel"
                            variant="secondary"
                            onClick={() => {
                              setShowDateFormModal(!showDateFormModal);
                              setDateRange(null);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </Modal>
              </>
            )}
          </div>
        </div>
      )}

      <input
        ref={fileInputFieldRef}
        type="file"
        accept="image/jpeg,image/png,application/pdf"
        name="prescriptionFileUploaderInput"
        label="inputFile"
        onClick={(event) => {
          event.target.value = "";
        }}
        className="display-none"
        onChange={(event) => {
          fileHandler(event);
        }}
      />
    </>
  );
}

const PatientInfoCard = (props) => {
  return (
    <section
      className="inherit-parent-width padding-left-large padding-right-large padding-top-medium padding-bottom-medium"
      data-cy="patient-profile-card"
    >
      <div className="inherit-parent-width display-flex display-flex-direction-row flex-align-items-center">
        <RoundedProfilePicture
          size="large"
          onClick={() => {}}
          // src={patientProfileImageSrc(
          //   props.abdmPatients?.searchResults[patientId]?.healthId
          // )}
        />
        <div className="padding-left-medium flex-basis-100">
          <p className="font-family-gilroy-medium font-size-medium font-color-secondary margin-small">
            {props.patientId &&
            typeof props.profileData.fullName === "string" ? (
              props.profileData.fullName
            ) : (
              <>-</>
            )}
          </p>
          <p className="font-family-gilroy-medium font-size-small font-color-secondary margin-small">
            {props.patientId &&
            typeof props.profileData.phoneNumber === "string" ? (
              props.profileData.phoneNumber
            ) : (
              <>-</>
            )}
          </p>
          <div className="display-flex display-flex-direction-row flex-align-items-center margin-small">
            {props.abdmPatients.following[props.patientId] ? (
              <div
                data-cy="UN-FOLLOW-PATIENT"
                className="padding-0-point-4-em border-radius-default border-solid-1px-grey display-flex flex-align-items-center"
                onClick={() => {
                  props.unFollowPatient(props.patientId);
                }}
              >
                <div>
                  <img src={followingPatientIcon} alt="following" />
                </div>
                <div className=" padding-left-small">Following</div>
              </div>
            ) : (
              <div
                data-cy="FOLLOW_PATIENT"
                className="padding-0-point-4-em border-radius-default border-solid-1px-grey display-flex flex-align-items-center"
                onClick={() => {
                  props.followPatient(props.profileData);
                }}
              >
                <div>
                  <img src={followPatientIcon} alt="follow" />
                </div>
                <div className=" padding-left-small">Follow</div>
              </div>
            )}
            {props.abdmPatients.pinned[props.patientId] && (
              <div
                data-cy="UN-PIN-PATIENT"
                className="padding-0-point-4-em margin-left-medium border-radius-default border-solid-1px-grey display-flex flex-align-items-center"
                onClick={() => {
                  props.unPinPatient(props.patientId);
                }}
              >
                <div>
                  <PinIcon />
                </div>
                <div className=" padding-left-small">Pinned</div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="inherit-parent-width display-flex display-flex-direction-row flex-align-items-center flex-justify-content-space-between margin-top-medium margin-bottom-default">
        <div>
          <p className="font-family-gilroy-medium font-size-default font-color-secondary text-align-center">
            {/* {typeof milliSecondsToYearsOld() === "number"
              ? `${milliSecondsToYearsOld()}`
              : "-"} */}

            {props.profileData.yob
              ? new Date().getFullYear() - props.profileData.yob
              : "-"}
          </p>
          <p className="font-family-gilroy-regular font-size-small font-color-secondary text-align-center">
            Age
          </p>
        </div>
        <hr className="height-25px border-right-2px-lite-grey" />
        <div>
          <p className="font-family-gilroy-medium font-size-default font-color-secondary text-align-center">
            {props.profileData.gender ? props.profileData.gender : "-"}
          </p>
          <p className="font-family-gilroy-regular font-size-small font-color-secondary text-align-center">
            Gender
          </p>
        </div>
        <hr className="height-25px border-right-2px-lite-grey" />
        <div>
          <p className="font-family-gilroy-medium font-size-default font-color-secondary text-align-center">
            {props.profileData.bloodGroup ? props.profileData.bloodGroup : "-"}
          </p>
          <p className="font-family-gilroy-regular font-size-small font-color-secondary text-align-center">
            Blood
          </p>
        </div>
        <hr className="height-25px border-right-2px-lite-grey" />
        <div>
          <p className="font-family-gilroy-medium font-size-default font-color-secondary text-align-center">
            {props.profileData.height ? `${props.profileData.height} cm` : "-"}
          </p>
          <p className="font-family-gilroy-regular font-size-small font-color-secondary text-align-center">
            Height
          </p>
        </div>
        <hr className="height-25px border-right-2px-lite-grey" />
        <div>
          <p className="font-family-gilroy-medium font-size-default font-color-secondary text-align-center">
            {props.profileData.weight ? `${props.profileData.weight} kg` : "-"}
          </p>
          <p className="font-family-gilroy-regular font-size-small font-color-secondary text-align-center">
            Weight
          </p>
        </div>
      </div>
      <>
        <p className="margin-top-large margin-bottom-medium font-family-gilroy-regular font-size-small font-color-secondary text-align-center">
          ALLERGIES
        </p>

        <div className="inherit-parent-width display-flex display-flex-direction-row flex-align-items-center flex-justify-content-center flex-gap-0-point-5-em flex-wrap-wrap ">
          {props.profileData.allergies ? (
            props.profileData.allergies.map((allergy, index) => (
              <span
                key={`${index}-allergy`}
                className="padding-default background-color-grey border-radius-default font-family-gilroy-regular font-size-small font-color-secondary"
              >
                {allergy}
              </span>
            ))
          ) : (
            <span className="padding-default background-color-grey border-radius-default font-family-gilroy-regular font-size-small font-color-secondary">
              None
            </span>
          )}
        </div>
      </>
    </section>
  );
};

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    abdmPatients: state.abdmPatients,
    documents: state.documents,
    profile: state.profile,
    downloads: state.downloads,
    clinics: state.clinics,
    patients: state.patients
  };
};

const mapDispatchToProps = function () {
  return {
    getDocumentsData: (patientId) => getDocumentsData(patientId),
    findPatient: (patientId, type) => findPatient(patientId, type),
    isSubscriptionRequestPresent: (healthId, patientId) =>
      isSubscriptionRequestPresent(healthId, patientId),
    checkForNintoConnections: () => checkIfPatientConnectedToNinto(),
    sendSubscriptionRequest: (expiryMonth, healthId, patientId) =>
      sendSubscriptionRequest(expiryMonth, healthId, patientId),
    getUpdatedDocuments: () => getUpdatedDocuments(),
    getDocumentDownloadUrl: (documentType, documentId) => {
      getDocumentDownloadUrl(documentType, documentId);
    },
    unPinPatient: (patientId) => unPinPatient(patientId),
    getUploaderImageDownloadUrl: (uploaderType, uploaderId) =>
      getUploaderImageDownloadUrl(uploaderType, uploaderId),
    addDocumentFilter: (type, filterValue) =>
      addDocumentFilter(type, filterValue),
    removeDocumentFilter: (clear) => removeDocumentFilter(clear),
    checkConsentRequestPresent: (healthId, patientId, loading) =>
      checkConsentRequestPresent(healthId, patientId, loading),
    sendConsentRequest: (data) => sendConsentRequest(data),
    getDataTransferDetails: (patientId) => getDataTransferDetails(patientId),
    followPatient: (profileData) => followPatient(profileData),
    unFollowPatient: (patientId) => unFollowPatient(patientId),
    retryDataTransfer: (data) => retryDataTransfer(data)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientProfile);

function PatientCardSuspense() {
  return (
    <article className="flex-center-children-horizontally margin-top-medium">
      <main className=" inherit-parent-width">
        <div className="inherit-parent-width flex-justify-start ">
          <div className="shimmer border-radius-100-percentage padding-larger margin-default margin-left-larger border-radius-default">
            <div className="padding-medium margin-default border-radius-default" />
          </div>
          <div className="width-50-percentage margin-top-default margin-right-larger">
            <div className="shimmer padding-default margin-default border-radius-default" />
            <div className="shimmer padding-default margin-default border-radius-default" />
            <div className="padding-smaller margin-smaller border-radius-default" />
            <div className="shimmer padding-default margin-default border-radius-default width-20-percentage" />
          </div>
        </div>
        <div className="inherit-parent-width flex-justify-content-space-around ">
          <div className="shimmer padding-default margin-default border-radius-25-percentage height-width-30px" />
          <div className="shimmer padding-default margin-default border-radius-25-percentage height-width-30px" />
          <div className="shimmer padding-default margin-default border-radius-25-percentage height-width-30px" />
          <div className="shimmer padding-default margin-default border-radius-25-percentage height-width-30px" />
          <div className="shimmer padding-default margin-default border-radius-25-percentage height-width-30px" />
          <div className="shimmer padding-default margin-default border-radius-25-percentage height-width-30px" />
        </div>
        <div>
          <div className="shimmer padding-larger margin-left-larger margin-right-larger margin-top-medium margin-bottom-larger border-radius-default " />
        </div>
      </main>
    </article>
  );
}

const SubscriptionRequired = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [expireMonth, setExpireMonth] = useState(6);
  const MonthsCard = (props) => {
    return (
      <div
        className={`${
          props.selected ? "border-primaryColor" : "border-tertiaryColor"
        } font-family-gilroy-regular font-size-small border-radius-default inherit-parent-width padding-small display-flex flex-justify-content-center flex-align-items-center margin-horizontal-small`}
        onClick={() => {
          props.monthsCardOnClick();
        }}
      >
        {props.selected && <TickIcon color={"#00A000"} width={12} />}

        <div
          className={
            props.selected &&
            "padding-left-small font-color-primary text-align-center "
          }
        >
          {props.months} months
        </div>
      </div>
    );
  };
  return (
    <section
      data-cy="subscriptionRequired"
      className=" text-align-center inherit-parent-width padding-horizontal-larger margin-top-larger display-flex flex-align-items-center flex-direction-column flex-justify-content-center"
    >
      <img src={fallback} alt="Fallback logo" className=" margin-top-larger" />
      <div className=" margin-top-larger font-family-gilroy-medium font-size-medium font-family-gilroy-bold font-color-secondary">
        {!props.isPatientSubscriptionRequestSent
          ? "Patient not subscribed."
          : "Connection request sent!"}
      </div>

      <div className=" text-align-center font-family-gilroy-regular font-color-secondary font-size-medium line-height-140-percentage padding-top-large display-flex flex-align-items-center flex-justify-content-center">
        {!props.isPatientSubscriptionRequestSent
          ? "Request your clinic to connect the patient with you to access all the medical records."
          : "Connection request sent to your clinic to access all the medical records."}
      </div>

      <Button
        boxShadow={false}
        data-cy="connect-now-button"
        className=" margin-top-larger"
        onClick={() => {
          setShowModal(true);
        }}
        text={
          props.isRequestExists
            ? "Resend subscription request"
            : "Send subscription request"
        }
        variant={props.isRequestExists ? "primaryBorder" : "primary"}
        loading={props.loading}
      />

      <Modal
        onClose={() => {
          setShowModal(false);
        }}
        canIgnore={true}
        show={showModal !== false}
        position="position-fixed bottom-0 left-0 right-0"
        boxShadow="none"
        borderRadius="none"
        width="inherit-parent-width"
        background="background-transparent"
        height="height-fit-to-content"
      >
        <div className="background-white font-family-gilroy-medium user-select-none ">
          <section className=" max-width-588px margin-horizontal-auto padding-larger">
            Access duration
            <div className=" padding-top-large display-flex padding-bottom-larger">
              {[3, 6, 12].map((month, index) => (
                <MonthsCard
                  key={index}
                  months={month}
                  selected={expireMonth === month}
                  monthsCardOnClick={() => {
                    // expireMonth !== month
                    //   ?
                    setExpireMonth(month);
                    // : setExpireMonth(0);
                  }}
                />
              ))}
            </div>
            <div className=" display-flex">
              <Button
                type={"submit"}
                boxShadow={false}
                text="Send request"
                disabled={props.approveButtonDisabled}
                onClick={(event) => {
                  props.sendSubscriptionRequestOnClick(expireMonth);
                  setShowModal(false);
                }}
                loading={props.loading}
                className={"border-1px-e5e5e5 margin-right-small"}
                data-cy={`send-button`}
              />

              <Button
                disabled={props.loading}
                boxShadow={false}
                text="Cancel"
                variant={"secondary"}
                onClick={(event) => {
                  setShowModal(false);
                }}
                className={"  border-1px-e5e5e5 margin-left-small"}
                data-cy={`cancel-button`}
              />
            </div>
          </section>
        </div>
      </Modal>
    </section>
  );
};

const ConsentRequestRequired = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [expireMonth, setExpireMonth] = useState(6);
  const MonthsCard = (props) => {
    return (
      <div
        className={`${
          props.selected ? "border-primaryColor" : "border-tertiaryColor"
        } font-family-gilroy-regular font-size-small border-radius-default inherit-parent-width padding-small display-flex flex-justify-content-center flex-align-items-center margin-horizontal-small`}
        onClick={() => {
          props.monthsCardOnClick();
        }}
      >
        {props.selected && <TickIcon color={"#00A000"} width={12} />}

        <div
          className={
            props.selected &&
            "padding-left-small font-color-primary text-align-center "
          }
        >
          {props.months} months
        </div>
      </div>
    );
  };
  return (
    <section
      data-cy="nintoConnectionRequired"
      className=" text-align-center  inherit-parent-width padding-horizontal-larger margin-top-larger display-flex flex-align-items-center flex-direction-column flex-justify-content-center"
    >
      <img src={fallback} alt="Fallback logo" className=" margin-top-larger" />

      <div className=" margin-top-larger font-family-gilroy-medium font-size-medium font-family-gilroy-bold font-color-secondary">
        {!props.isRequestExists
          ? "Patient’s consent needed"
          : "Connection request sent!"}
      </div>

      <div className=" text-align-center font-family-gilroy-regular font-color-secondary font-size-medium line-height-140-percentage padding-top-large display-flex flex-align-items-center flex-justify-content-center">
        {!props.isRequestExists
          ? "Request your clinic to connect the patient with you to access all the medical records."
          : "Connection request sent to your clinic to access all the medical records."}
      </div>

      <div className="inherit-parent-width padding-top-large">
        {
          <Button
            type="button"
            boxShadow={false}
            loading={props.loading}
            className="max-height-50px font-size-large"
            text={
              props.isRequestExists
                ? "Resend consent request"
                : "Send consent request"
            }
            variant={props.isRequestExists ? "primaryBorder" : "primary"}
            // disabled={true}

            onClick={() => {
              setShowModal(true);
            }}
          />
        }
      </div>

      <Modal
        onClose={() => {
          setShowModal(false);
        }}
        canIgnore={true}
        show={showModal !== false}
        position="position-fixed bottom-0 left-0 right-0"
        boxShadow="none"
        borderRadius="none"
        width="inherit-parent-width"
        background="background-transparent"
        height="height-fit-to-content"
      >
        <div className="background-white font-family-gilroy-medium user-select-none ">
          <section className=" max-width-588px margin-horizontal-auto padding-larger">
            Access duration
            <div className=" padding-top-large display-flex padding-bottom-larger">
              {[3, 6, 12].map((month, index) => (
                <MonthsCard
                  key={index}
                  months={month}
                  selected={expireMonth === month}
                  monthsCardOnClick={() => {
                    // expireMonth !== month
                    //   ?
                    setExpireMonth(month);
                    // : setExpireMonth(0);
                  }}
                />
              ))}
            </div>
            <div className=" display-flex">
              <Button
                type={"submit"}
                boxShadow={false}
                text="Send request"
                disabled={props.approveButtonDisabled}
                onClick={(event) => {
                  props.sendConsentRequestOnClick(expireMonth);
                  setShowModal(false);
                }}
                loading={props.loading}
                className={"border-1px-e5e5e5 margin-right-small"}
                data-cy={`send-button`}
              />

              <Button
                disabled={props.loading}
                boxShadow={false}
                text="Cancel"
                variant={"secondary"}
                onClick={(event) => {
                  setShowModal(false);
                }}
                className={"  border-1px-e5e5e5 margin-left-small"}
                data-cy={`cancel-button`}
              />
            </div>
          </section>
        </div>
      </Modal>
    </section>
  );
};
