import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import "./Login.css";
import Button from "../../front-end-global-components/components/Button/Button";
import OTPInputBox from "../../front-end-global-components/components/OTPInputBox/OTPInputBox";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import { reCaptchaVerifier } from "../../services/authentication";
import Header from "../../front-end-global-components/components/Header/Header";
import {
  clearCredentials,
  sendOtp,
  verifyOtp
} from "../../redux/authentication/actions";
import { mockAuthVerifyOtp } from "../../redux/mock/actions";

function Login(props) {
  const [loginFormData, setLoginFormData] = useState({
    phoneNumber: "+91",
    OTP: null
  });

  //invisible recaptcha for authentication
  useEffect(() => {
    window.reCaptchaVerifier = reCaptchaVerifier();
  }, []);

  const setOTPValue = (otp) => {
    if (loginFormData.phoneNumber === null) {
    }
    setLoginFormData({
      ...loginFormData,
      OTP: otp
    });
  };

  const loginFormChange = (event) => {
    //To clear auth verification while user trying to change phone number after otp sent
    if (
      event.target.name === "phoneNumber" &&
      props.auth.credentials.verificationId &&
      loginFormData.phoneNumber !== event.target.value
    ) {
      props.clearCredentials();
    }
    if (event.target.name.includes("otp")) {
      return;
    } else {
      setLoginFormData({
        ...loginFormData,
        [event.target.name]: event.target.value
      });
    }
  };

  const otpRequestHandler = (event) => {
    event.preventDefault();
    props.sendOtp(loginFormData.phoneNumber, "login");
  };

  const otpVerifyHandler = (event) => {
    event.preventDefault();
    if (process.env.REACT_APP_STAGING === "local") {
      props.mockAuthWithOTP("login", loginFormData.OTP);
    } else {
      props.verifyOtp(loginFormData.OTP);
    }
  };

  const isOtpButtonDisabled = () => {
    if (
      typeof loginFormData.phoneNumber === "string" &&
      loginFormData.phoneNumber.trim().length >= 13
    ) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <div className="inherit-parent-height">
      <article className="inherit-parent-height" data-cy="login-screen">
        <Header
          userType="doctor"
          backButtonOnClick={() => {}}
          hideSupport={true}
          hideBackButton
          maxStepperCount={3}
          currentStepperValue={0}
          supportIconOnClick={() => {}}
        />
        <form
          className="remaining-body-height overflow-x-scroll width-100-percent grey-abstract-background-image flex-center-children-vertically flex-direction-column"
          onChange={(event) => {
            loginFormChange(event);
          }}
          onSubmit={(event) => event.preventDefault()}
        >
          <main className="padding-larger inherit-parent-width max-width-500px flex-grow">
            <h1 className="font-weight-normal font-family-gilroy-medium font-size-small font-color-secondary inherit-parent-width text-align-center">
              DOCTOR LOGIN
            </h1>
            <InputBox
              className="inherit-parent-width border-radius-default margin-vertical-large"
              name="phoneNumber"
              label="Phone Number"
              type="tel"
              autoComplete="on"
              value={loginFormData.phoneNumber}
              buttonTileId="login-button"
              buttonTile={
                props.auth.credentials.verificationId !== null
                  ? "Resend OTP"
                  : "Get OTP"
              }
              buttonType={
                typeof props.auth.credentials.verificationId === "string"
                  ? "button"
                  : "submit"
              }
              onButtonClick={(event) => {
                otpRequestHandler(event);
              }}
              data-cy="login-screen-phone-number-field"
              required
              suffixButtonDisabled={isOtpButtonDisabled()}
            />
            {props.auth.credentials.verificationId && (
              <OTPInputBox
                name="otp"
                setValue={setOTPValue}
                className="margin-bottom-large"
                required
              />
            )}
          </main>
          <footer className="padding-larger inherit-parent-width max-width-500px">
            <Button
              data-cy="login-button"
              loading={props.auth.loading}
              type={
                typeof props.auth.credentials.verificationId === "string"
                  ? "submit"
                  : "button"
              }
              className="margin-bottom-large"
              text="Login"
              onClick={(event) => {
                otpVerifyHandler(event);
              }}
              disabled={
                !(
                  typeof loginFormData.OTP === "string" &&
                  loginFormData.OTP.length === 6
                )
              }
              boxShadow={false}
            />
            <h2 className="font-weight-normal font-family-gilroy-medium font-size-small font-color-secondary inherit-parent-width text-align-center">
              DON`T HAVE AN ACCOUNT?
              <span
                className="margin-left-default font-color-primary"
                onClick={() => {
                  props.clearCredentials();
                  props.navigate("/signup");
                }}
              >
                Signup
              </span>
            </h2>
          </footer>
        </form>
        <div id="recaptcha-placeholder"></div>
      </article>
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    profile: state.profile
  };
};

const mapDispatchToProps = function () {
  return {
    verifyOtp: (loginOtp) => verifyOtp(loginOtp),
    sendOtp: (phoneNumber, type) => sendOtp(phoneNumber, type),
    mockAuthWithOTP: (type, otp) => mockAuthVerifyOtp(type, otp),
    clearCredentials: () => clearCredentials()
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
