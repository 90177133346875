import React, { useState, useEffect } from "react";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import {
  isValidObject,
  isValidArray
} from "../../front-end-global-components/services/validators";
import { connect } from "react-redux";

import selectedIcon from "../../assets/icons/success.svg";
import settingIcon from "../../assets/icons/Settings.svg";
import { setCurrentClinic } from "../../redux/clinics/actions";
import { removeDocumentFilter } from "../../redux/documents/actions";
import { setNotificationRead } from "../../redux/notifications/actions";

function ClinicSwitcherModal(props) {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (isValidObject(props.notifications.data)) {
      let _notifications = [];
      Object.keys(props.notifications.data).forEach((notificationType) => {
        if (
          notificationType === "global" ||
          notificationType === props.clinics.currentClinic
        ) {
          _notifications = [
            ..._notifications,
            ...props.notifications.data[notificationType]
          ];
        }
      });
      //Sort based on TIME (milliseconds)
      _notifications = _notifications.sort((firstObject, secondObject) => {
        return (
          new Date(secondObject.timestamp) - new Date(firstObject.timestamp)
        );
      });

      //sort based on read false
      _notifications = _notifications.sort(function (
        firstObject,
        secondObject
      ) {
        // false values first
        return firstObject.read === secondObject.read
          ? 0
          : firstObject.read
          ? 1
          : -1;
      });
      setNotifications(_notifications);
    }
  }, [props.notifications.data, props.clinics.currentClinic]);

  const clinicsNotificationsCount = (clinicId) => {
    if (
      typeof clinicId === "string" &&
      isValidObject(props.notifications.data)
    ) {
      let clinicNotificationCount = 0;
      if (isValidArray(props.notifications.data[clinicId])) {
        clinicNotificationCount = props.notifications.data[clinicId].filter(
          (notification) => notification.read === false
        ).length;
      }
      return clinicNotificationCount;
    } else if (typeof clinicId !== "string" && isValidArray(notifications)) {
      return notifications.filter((notification) => notification.read === false)
        .length;
    } else {
      return null;
    }
  };

  const doctorProfilePictureSrc = () => {
    if (
      isValidObject(props.profile.data) &&
      isValidObject(props.profile.data[Object.keys(props.profile.data)])
    ) {
      const [userDocId] = Object.keys(props.profile.data);
      if (
        isValidObject(props.downloads.data) &&
        isValidObject(props.downloads.data.profilePictures) &&
        isValidObject(props.downloads.data.profilePictures.doctors) &&
        typeof props.downloads.data.profilePictures.doctors[userDocId] ===
          "string"
      ) {
        return props.downloads.data.profilePictures.doctors[userDocId];
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const connectedClinicsProfileImage = (clinicsUid) => {
    if (
      isValidObject(props.downloads.data) &&
      isValidObject(props.downloads.data.profilePictures) &&
      isValidObject(props.downloads.data.profilePictures.clinics) &&
      typeof props.downloads.data.profilePictures.clinics[clinicsUid] ===
        "string"
    ) {
      return props.downloads.data.profilePictures.clinics[clinicsUid];
    } else {
      return null;
    }
  };

  const headerModalHandler = () => {
    if (props.show === false) {
      props.setShow(true);
    } else {
      props.setShow(false);
    }
  };
  return (
    <Modal
      onClickClose={() => {
        headerModalHandler();
      }}
      show={props.show}
      position="position-fixed top-0 left-0"
      boxShadow="none"
      borderRadius="none"
      width="inherit-parent-width"
      background="background-transparent"
      height="inherit-parent-height"
    >
      <div
        data-cy="notification-modal-back-drop"
        className="inherit-parent-height display-flex flex-align-items-end flex-direction-column"
        onClick={headerModalHandler}
      >
        <div
          className="inherit-parent-width inherit-parent-height "
          style={{ flexBasis: "1" }}
        />
        <footer className=" inherit-parent-width background-white">
          {
            /* props.clinics.data */
            isValidObject(props.clinics.data) &&
            isValidObject(props.profile.data) ? (
              <div className="inherit-parent-width margin-horizontal-auto max-width-900px footer-padding-horizontal-larger">
                <div
                  className={` background-white
													inherit-parent-width  padding-horizontal-default switch-box clinic-switchig-modal-footer-wrapper 
													`}
                >
                  {Object.keys(props.clinics.data).map(
                    (clinicsDocId, index) => {
                      if (isValidObject(props.clinics.data[clinicsDocId])) {
                        return (
                          <div
                            className={`cursor-pointer inherit-parent-width border-radius-default
																		padding-default flex-justify-content-space-between font-size-small font-family-gilroy-medium
																		flex-direction-row margin-vertical-medium
																		
																		`}
                            key={index}
                            onClick={(event) => {
                              event.stopPropagation();
                              props.setCurrentClinic(clinicsDocId);
                              props.removeDocumentFilter("doctorAndClinic");
                            }}
                            data-cy={`${clinicsDocId}`}
                          >
                            <div className="display-flex flex-align-items-center">
                              <RoundedProfilePicture
                                notification-count-data-cy={`${clinicsDocId}-switching-tile-notification-count`}
                                size="medium"
                                notificationCount={clinicsNotificationsCount(
                                  clinicsDocId
                                )}
                                loading={props.clinics.loading}
                                onClick={() => {}}
                                src={connectedClinicsProfileImage(clinicsDocId)}
                              />
                              <div>
                                <p className=" font-family-gilroy-medium font-size-medium font-color-secondary  padding-left-large flex-basis-100 display-flex flex-align-items-center">
                                  {props.clinics.data[clinicsDocId].companyName}
                                </p>
                                <p className=" font-family-gilroy-medium font-color-secondary padding-top-small padding-left-large flex-basis-100 display-flex flex-align-items-center">
                                  {props.clinics.data[clinicsDocId].locality}
                                </p>
                              </div>
                            </div>

                            {props.clinics.currentClinic === clinicsDocId && (
                              <div className="display-flex flex-align-items-center">
                                <img
                                  className="padding-default height-width-2P5em"
                                  src={selectedIcon}
                                  alt="selected_icon"
                                />
                              </div>
                            )}
                          </div>
                        );
                      } else {
                        return null;
                      }
                    }
                  )}
                </div>
              </div>
            ) : (
              <>
                {isValidObject(props.profile.data) && (
                  <div
                    data-cy="user-modal"
                    className="inherit-parent-width margin-horizontal-auto max-width-900px footer-padding-horizontal-larger"
                  >
                    <div
                      className={`border-top-radius-default box-shadow-default background-white
															inherit-parent-width  padding-horizontal-default switch-box clinic-switchig-modal-footer-wrapper 
															`}
                    >
                      <div
                        className={`cursor-pointer inherit-parent-width border-radius-default
														padding-default flex-justify-content-space-between font-size-small font-family-gilroy-medium
														flex-direction-row margin-vertical-medium
									
														`}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      >
                        <RoundedProfilePicture
                          size="medium"
                          loading={props.profile.loading}
                          onClick={() => {}}
                          src={doctorProfilePictureSrc()}
                        />
                        <p className="font-family-gilroy-medium font-color-secondary font-size-medium padding-default padding-left-large flex-basis-100 display-flex flex-align-items-center">
                          {/* {
                            props.profile.data[
                              validProfileObject(props.profile.data)[1]
                            ].public.fullName
                          } */}
                          {
                            props.profile?.data?.[
                              Object.keys(props.profile.data)?.[0]
                            ]?.fullName
                          }
                        </p>
                        <div
                          className="cursor-pointer flex-place-children-page-center"
                          onClick={(event) => {
                            event.stopPropagation();
                            props.navigate("/editprofile");
                          }}
                          data-cy={"edit-profile-setting-icon"}
                        >
                          <img
                            className="padding-default height-width-2P7em"
                            src={settingIcon}
                            alt="settingIcon"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )
          }
        </footer>
      </div>
    </Modal>
  );
}
const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    profile: state.profile,
    clinics: state.clinics,
    notifications: state.notifications,
    downloads: state.downloads
  };
};

const mapDispatchToProps = function () {
  return {
    setCurrentClinic: (clinicUid) => setCurrentClinic(clinicUid),
    setNotificationRead: (notification) => setNotificationRead(notification),
    removeDocumentFilter: (type) => removeDocumentFilter(type)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClinicSwitcherModal);
