import React, { useState, useRef, useEffect } from "react";
import "cropperjs/dist/cropper.css";
import { connect } from "react-redux";
import Header from "../../../front-end-global-components/components/Header/Header";
import InputBox from "../../../front-end-global-components/components/InputBox/InputBox";
import default_user_img from "../../../assets/icons/default_user.svg";
import RoundedProfilePicture from "../../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import logoutLogo from "../../../assets/icons/Logout.svg";
import abdmLogo from "../../../assets/icons/ABDM logo.svg";
import Modal from "../../../front-end-global-components/components/Modal/Modal";
import Button from "../../../front-end-global-components/components/Button/Button";
import "./EditProfile.css";
import {
  isValidArray,
  isValidObject,
  isValidString
} from "../../../services/validators";
import { validProfileObject, validValue } from "../../../services/utils";
import NativeSelect from "../../../front-end-global-components/components/NativeSelect/NativeSelect";
import Select from "../../../front-end-global-components/components/Select/Select";

import ActionsHeader from "../../../components/ActionsHeader/ActionsHeader";
import noConnectedClinicsFallBack from "../../../assets/images/noConnectedClinicsFallBack.svg";
import { CSSTransition } from "react-transition-group";
import cancel_icon from "../../../assets/icons/cancel_icon.svg";
// import green_tick_icon from "../../../assets/icons/green_tick_icon.svg";
import {
  editProfileRequest,
  setProfilePicture
} from "../../../redux/profile/actions";
import { logout } from "../../../redux/authentication/actions";
import {
  removeClinicRequest,
  removeConnectedClinic
} from "../../../redux/clinics/actions";
import {
  dateOfBirthValidation,
  yearOfExperienceValidation
} from "../../../utils/constants";

function EditProfile(props) {
  const [currentSelectedAction, setCurrentSelectedAction] = useState(
    "PROFILE_INFORMATION"
  );

  const profilePicture = () => {
    const [userDocId] = Object.keys(props.profile.data);
    if (
      isValidObject(props.downloads.data) &&
      isValidObject(props.downloads.data.profilePictures) &&
      isValidObject(props.downloads.data.profilePictures.doctors) &&
      typeof props.downloads.data.profilePictures.doctors[userDocId] ===
        "string"
    ) {
      return props.downloads.data.profilePictures.doctors[userDocId];
    } else {
      return default_user_img;
    }
  };

  return (
    <div className="inherit-parent-height">
      <Header
        title={"Profile"}
        backButtonOnClick={() => props.navigate(-1)}
        supportIconOnClick={() => {
          props.navigate("/queries");
        }}
      />
      {validProfileObject(props.profile.data) && (
        <section
          data-cy="edit-profile-screen"
          className="remaining-body-height overflow-x-scroll flex-start-place-children-vertically "
        >
          <ActionsHeader
            title={validValue(
              () =>
                props.profile?.data?.[Object.keys(props.profile.data)?.[0]]
                  ?.fullName
            )}
            subTitle={validValue(
              () =>
                props.profile?.data?.[Object.keys(props.profile.data)?.[0]]
                  ?.phoneNumber
            )}
            picture={profilePicture()}
            editMode={true}
            currentSelectedAction={currentSelectedAction}
            setCurrentSelectedAction={setCurrentSelectedAction}
            requiredActions={[
              { value: "PROFILE_INFORMATION" },
              {
                value: "CLINIC_CONNECTIONS",
                badgeCount: validValue(() => props.clinics.requests.length)
              }
            ]}
            newProfilePictureName={
              props.profile.data[Object.keys(props.profile.data)].public.uid
            }
            onProfilePictureSave={(file) => {
              props.setProfilePicture(file);
            }}
          />
          <CSSTransition
            in={currentSelectedAction === "PROFILE_INFORMATION"}
            timeout={2000}
            classNames="my"
          >
            <>
              {currentSelectedAction === "PROFILE_INFORMATION" && (
                <section className="padding-medium inherit-parent-width remaining-height-actions-header overflow-y-auto display-flex flex-direction-column flex-align-items-center ">
                  <ProfileInformation {...props} />
                </section>
              )}
            </>
          </CSSTransition>

          <CSSTransition
            in={currentSelectedAction === "CLINIC_CONNECTIONS"}
            timeout={2000}
            classNames="my"
          >
            <>
              {currentSelectedAction === "CLINIC_CONNECTIONS" && (
                <section className="padding-medium inherit-parent-width remaining-height-actions-header overflow-y-auto ">
                  <ConnectedClinics {...props} />
                </section>
              )}
            </>
          </CSSTransition>
        </section>
      )}
    </div>
  );
}

const ProfileInformation = (props) => {
  const [showModal, setShowModal] = useState(false);
  const isValidDate = useRef(true);
  const isValidEmail = useRef(true);
  const isValidYearOfExperience = useRef(true);

  const [formObject, setFormObject] = useState({
    email: null,
    gender: "Male",
    city: null,
    speciality: [],
    date: null,
    month: "",
    year: null,
    yearOfExperience: null,
    dateOfBirth: null
  });

  useEffect(() => {
    if (validProfileObject(props.profile.data)) {
      const [docId] = Object.keys(props.profile.data);
      const userObj = props.profile.data[docId];
      if (isValidObject(userObj.public)) {
        const newObj = {
          email: userObj.email ? userObj.email : null,
          gender: userObj.public.gender
            ? getConvertedGender(userObj.public.gender, true)
            : "",
          city: userObj.public.city ? userObj.public.city : null,
          speciality: isValidArray(userObj.public.speciality)
            ? userObj.public.speciality
            : [],
          dateOfBirth: userObj.dateOfBirth
            ? new Date(userObj.dateOfBirth).toISOString().split("T")[0]
            : null,
          yearOfExperience:
            isValidObject(userObj.public.experience) &&
            typeof userObj.public.experience.yearOfExperience === "number"
              ? userObj.public.experience.yearOfExperience
              : null
        };

        if (window.document.activeElement) {
          delete newObj[window.document.activeElement.getAttribute("name")];
        }
        setFormObject(newObj);
      }
    }
    // eslint-disable-next-line
  }, [JSON.stringify(props.profile.data)]);

  const getConvertedGender = (gender, toCamelCase) => {
    if (gender === "Male" || gender === "male") {
      return toCamelCase ? "Male" : "male";
    } else if (gender === "Female" || gender === "female") {
      return toCamelCase ? "Female" : "female";
    } else if (gender === "Others" || gender === "others") {
      return toCamelCase ? "Others" : "others";
    } else {
      return "";
    }
  };

  const onFormInputChange = (event) => {
    if (event.target.name === "speciality") {
      return;
    }
    if (event.target.name === "gender") {
      setFormObject({
        ...formObject,
        [event.target.name]: getConvertedGender(event.target.value)
      });
    } else {
      setFormObject({ ...formObject, [event.target.name]: event.target.value });
    }
  };

  const onEmailOutOfFocus = (event) => {
    if (
      !(event && event.target.value && event.target.value.trim().length > 0)
    ) {
      return;
    }

    if (!isValidEmail.current) {
      return;
    }
    if (!validProfileObject(props.profile.data)) {
      return;
    }
    const [docId] = Object.keys(props.profile.data);
    const profileData = props.profile.data[docId];

    if (
      profileData.hasOwnProperty("email") &&
      isValidString(profileData.email) &&
      profileData.email === event.target.value
    ) {
      return;
    }

    props.editProfileRequest(
      {
        email: event.target.value
      },
      `${event.target.name}`
    );
  };

  const onDateOfBirthOutOfFocus = (event) => {
    if (
      !(event && event.target.value && event.target.value.trim().length > 0)
    ) {
      return;
    }

    if (!validProfileObject(props.profile.data)) {
      return;
    }
    const [docId] = Object.keys(props.profile.data);
    const profileData = props.profile.data[docId];

    // In order to get accurate milliseconds -1 month
    // In order to set FEB(2) put JAN(1)
    // const janDate = +new Date(2021, 1, 15); => will give FEB
    const date = +new Date(event.target.value);
    if (
      profileData.hasOwnProperty("dateOfBirth") &&
      typeof profileData.dateOfBirth === "number" &&
      profileData.dateOfBirth === date
    ) {
      return;
    }

    props.editProfileRequest(
      {
        dateOfBirth: date
      },
      "dateOfBirth"
    );
  };

  const onGenderOutOfFocus = (event) => {
    if (
      !(event && event.target.value && event.target.value.trim().length > 0)
    ) {
      return;
    }

    if (!validProfileObject(props.profile.data)) {
      return;
    }
    const [docId] = Object.keys(props.profile.data);
    const profileData = props.profile.data[docId];

    if (
      profileData.public.hasOwnProperty("gender") &&
      isValidString(profileData.public.gender) &&
      profileData.public.gender === event.target.value
    ) {
      return;
    }

    props.editProfileRequest(
      {
        gender: getConvertedGender(event.target.value)
      },
      "gender"
    );
  };

  const onCityOutOfFocus = (event) => {
    if (
      !(event && event.target.value && event.target.value.trim().length > 0)
    ) {
      return;
    }

    if (!validProfileObject(props.profile.data)) {
      return;
    }
    const [docId] = Object.keys(props.profile.data);
    const profileData = props.profile.data[docId];

    if (
      profileData.public.hasOwnProperty("city") &&
      isValidString(profileData.public.city) &&
      profileData.public.city === event.target.value
    ) {
      return;
    }

    props.editProfileRequest(
      {
        city: event.target.value
      },
      "city"
    );
  };

  const onSpecialtyAdded = (listValue) => {
    if (!isValidArray(listValue)) {
      return;
    }

    if (!validProfileObject(props.profile.data)) {
      return;
    }
    const [docId] = Object.keys(props.profile.data);
    const profileData = props.profile.data[docId];

    if (isValidArray(profileData.public.speciality)) {
      const isSame =
        profileData.public.speciality.length === listValue.length &&
        profileData.public.speciality.every((element, index) => {
          return element === listValue[index];
        });
      if (!isSame) {
        props.editProfileRequest(
          {
            speciality: [...listValue]
          },
          "speciality"
        );
      }
    } else {
      props.editProfileRequest(
        {
          speciality: [...listValue]
        },
        "speciality"
      );
    }
  };

  const onYearOfExperienceOutOfFocus = (event) => {
    if (
      !(event && event.target.value && event.target.value.trim().length > 0)
    ) {
      return;
    }

    if (!validProfileObject(props.profile.data)) {
      return;
    }
    const [docId] = Object.keys(props.profile.data);
    const profileData = props.profile.data[docId];

    if (
      isValidObject(profileData.public.experience) &&
      typeof profileData.public.experience.yearOfExperience === "number" &&
      profileData.public.experience.yearOfExperience ===
        parseInt(event.target.value)
    ) {
      return;
    }

    if (isValidObject(profileData)) {
      props.editProfileRequest(
        {
          experience: {
            yearOfExperience: parseInt(event.target.value)
          }
        },
        "yearOfExperience"
      );
    }
  };
  return (
    <>
      <p className="inherit-parent-width display-flex flex-justify-content-center padding-medium padding-bottom-small font-weight-normal font-family-gilroy-regular font-size-small">
        PROFILE INFORMATION
      </p>
      <form
        className="inherit-parent-width max-width-500px"
        onChange={(event) => {
          onFormInputChange(event);
        }}
        onSubmit={(e) => e.preventDefault()}
      >
        <InputBox
          className="inherit-parent-width border-radius-default margin-vertical-large"
          type="text"
          name="email"
          label="Email"
          labelClassName="letter-spacing-4-percentage"
          value={formObject.email}
          onOutOfFocus={onEmailOutOfFocus}
          loading={
            props.profile.loading === true &&
            props.profile.currentField === "email"
          }
          isValidInput={(foo) => {
            isValidEmail.current = foo;
          }}
        />
        <InputBox
          className="inherit-parent-width border-radius-default margin-vertical-large"
          type="date"
          name="dateOfBirth"
          labelClassName="letter-spacing-4-percentage"
          label="Date Of Birth"
          value={formObject.dateOfBirth}
          onOutOfFocus={onDateOfBirthOutOfFocus}
          loading={
            props.profile.loading === true &&
            props.profile.currentField === "dateOfBirth"
          }
          isValidInput={(foo) => {
            isValidDate.current = foo;
          }}
          validation={dateOfBirthValidation}
        />
        <NativeSelect
          className="inherit-parent-width margin-top-large"
          name="gender"
          label="Gender"
          labelClassName="letter-spacing-4-percentage"
          defaultValue={formObject.gender}
          options={["", "Male", "Female", "Others"]}
          onOutOfFocus={onGenderOutOfFocus}
        />
        <InputBox
          className="inherit-parent-width border-radius-default margin-vertical-large"
          type="text"
          name="city"
          label="City"
          labelClassName="letter-spacing-4-percentage"
          value={formObject.city}
          onOutOfFocus={onCityOutOfFocus}
          loading={
            props.profile.loading === true &&
            props.profile.currentField === "city"
          }
        />
        <Select
          chips={formObject.speciality}
          modalTitle={"Choose your Specialties"}
          multiSelect={true}
          name="speciality"
          label="specialty"
          labelClassName="letter-spacing-4-percentage"
          onChipAdded={onSpecialtyAdded}
          loading={
            props.profile.loading === true &&
            props.profile.currentField === "speciality"
          }
          data-cy="speciality-select-box-comp"
          options={[
            "Allergy and immunology",
            "Orthopedics",
            "Anesthesiology",
            "Dermatology",
            "Diagnostic radiology",
            "Family medicine",
            "Internal medicine",
            "Medical genetics",
            "Neurology",
            "Nuclear medicine",
            "Obstetrics and gynecology",
            "Ophthalmology",
            "Pathology",
            "Pediatrics",
            "Physical medicine and rehabilitation",
            "Preventive medicine",
            "Psychiatry",
            "Radiation oncology",
            "Surgery",
            "Urology"
          ]}
        />
        <InputBox
          className="inherit-parent-width border-radius-default margin-vertical-large"
          name="yearOfExperience"
          label="Years of Experience"
          labelClassName="letter-spacing-4-percentage"
          type="number"
          autoComplete="false"
          required
          value={formObject.yearOfExperience}
          onOutOfFocus={onYearOfExperienceOutOfFocus}
          loading={
            props.profile.loading === true &&
            props.profile.currentField === "yearOfExperience"
          }
          isValidInput={(foo) => {
            isValidYearOfExperience.current = foo;
          }}
          validation={yearOfExperienceValidation}
        />
      </form>
      <div className="inherit-parent-width max-width-500px padding-vertical-medium margin-bottom-larger">
        <button
          className="background-white font-family-gilroy-medium font-size-medium float-left"
          onClick={() => {
            props.navigate("/abdm/authentication");
          }}
        >
          <img src={abdmLogo} className="height-0P8em" alt="ABDM_logo" />
          <span className=" font-color-secondary margin-left-medium ">
            ABDM
          </span>
        </button>
      </div>
      <div className="inherit-parent-width max-width-500px margin-bottom-larger">
        <button
          className="background-white font-family-gilroy-medium font-size-medium float-left"
          onClick={() => setShowModal(true)}
        >
          <img src={logoutLogo} className="height-0P8em" alt="logout_logo" />
          <span className="font-color-red margin-left-medium ">Logout</span>
        </button>
      </div>
      {showModal && (
        <Modal
          show={showModal}
          width="inherit-parent-width"
          maxWidth="max-width-588px"
          background="false"
          boxShadow="false"
          borderRadius="false"
          height="height-fit-to-content"
          modalBodyClassName="padding-horizontal-medium"
        >
          <div className="background-white padding-medium border-radius-default box-shadow-default ">
            <div className="font-family-gilroy-bold font-size-medium font-color-secondary text-align-center padding-bottom-larger">
              Are you sure you want to logout?
            </div>
            <div className="display-flex">
              <Button
                boxShadow={false}
                loading={props.auth.loading}
                className="margin-right-small"
                variant="danger"
                text="Logout"
                onClick={() => {
                  props.logout();
                }}
              />
              <Button
                boxShadow={false}
                className="margin-left-small"
                variant="secondary"
                text="Cancel"
                onClick={() => setShowModal(false)}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

const ConnectedClinics = (props) => {
  const connectedClinicProfileImage = (clinicUid) => {
    const clinicProfilePicture = validValue(
      () => props.downloads.data.profilePictures.clinics[clinicUid]
    );
    if (typeof clinicProfilePicture === "string") {
      return clinicProfilePicture;
    } else {
      return null;
    }
  };

  const requestedClinics = () => {
    let result = [];
    if (isValidArray(props.clinics.requests)) {
      if (isValidObject(props.clinics.data)) {
        result = [];
        const filteredList = [...props.clinics.requests];
        Object.keys(props.clinics.data).forEach((clinicId) => {
          filteredList.some((data, index) => {
            if (data.clinic === clinicId) {
              filteredList.splice(index, 1);
            }
            return data.clinic === clinicId;
          });
        });
        result = [...filteredList];
      } else {
        result = [];
        result = [...props.clinics.requests];
      }
      return result;
    } else {
      return result;
    }
  };

  return (
    <div className="inherit-parent-width  remaining-height-actions-header">
      {props.clinics.loading === true ? (
        <ClinicConnectionsSuspense />
      ) : (
        <>
          {/* {isValidArray(requestedClinics()) && (
            <>
              <p className="inherit-parent-width display-flex flex-justify-content-center padding-medium padding-bottom-small font-weight-normal font-family-gilroy-regular font-size-small">
                NEW CONNECTION INVITE
              </p>
              {requestedClinics().map((data, index) => {
                return (
                  <article
                    className="cursor-pointer padding-top-medium padding-bottom-medium padding-left-small padding-right-small font-size-medium flex-start flex-align-items-center font-color-secondary"
                    key={`requested-clinic-${index}`}
                    onClick={() => {
                      props.acceptConnectionRequest(data.documentId);
                    }}
                    data-cy={`requested-clinic-${data.clinic}`}
                  >
                    <RoundedProfilePicture
                      size="medium"
                      loading={false}
                      onClick={() => {}}
                      src={connectedClinicProfileImage(data.clinic)}
                      data-cy={"pinned-patient-profile-image"}
                    />
                    <div className="margin-horizontal-large">
                      {typeof data.clinicName === "string" && (
                        <h5 className="font-weight-normal font-family-gilroy-medium font-size-medium font-weight-normal ">
                          {data.clinicName}
                        </h5>
                      )}
                      {typeof data.clinicLocality === "string" && (
                        <p className="font-size-small">{data.clinicLocality}</p>
                      )}
                    </div>
                    <div className="margin-left-auto">
                      <img
                        alt="accept"
                        src={green_tick_icon}
                        className="margin-right-larger"
                      />
                      <img
                        data-cy={`remove-requested-clinic-${data.clinic}`}
                        alt="remove"
                        src={cancel_icon}
                        onClick={(event) => {
                          event.stopPropagation();
                          props.removeClinicRequest(data.documentId);
                        }}
                      />
                    </div>
                  </article>
                );
              })}
            </>
          )} */}
          {isValidObject(props.clinics.data) ? (
            <>
              <p className="inherit-parent-width display-flex flex-justify-content-center padding-medium padding-bottom-small font-weight-normal font-family-gilroy-regular font-size-small">
                YOUR CONNECTED CLINICS
              </p>
              {Object.keys(props.clinics.data).map((clinicId, index) => {
                const _clinicData = props.clinics.data[clinicId];
                return _clinicData.delete === true ? (
                  <section
                    className="flex-center-children-vertically "
                    data-cy={`to-be-removed-connected-clinic-${clinicId}`}
                  >
                    <div className="shimmer padding-larger margin-small border-radius-100-percentage" />
                    <div className="width-80percentage">
                      <div className="shimmer padding-small margin-default border-radius-default width-80percentage" />
                      <div className="shimmer padding-small margin-default border-radius-default width-30percentage" />
                    </div>
                  </section>
                ) : (
                  <article
                    className="cursor-pointer padding-top-medium padding-bottom-medium padding-left-small padding-right-small font-size-medium flex-start flex-align-items-center font-color-secondary"
                    key={`connected-clinic-${index}`}
                    onClick={() => {}}
                    data-cy={`connected-clinic-${clinicId}`}
                  >
                    <RoundedProfilePicture
                      size="medium"
                      loading={false}
                      onClick={() => {}}
                      data-cy={"pinned-patient-profile-image"}
                      src={connectedClinicProfileImage(clinicId)}
                      setFile={(data) => {
                        // console.log("setFile", data);
                      }}
                    />
                    <div className="margin-horizontal-large">
                      <h5 className="font-weight-normal font-family-gilroy-medium font-size-medium font-weight-normal ">
                        {_clinicData.companyName}
                      </h5>
                      {typeof _clinicData.locality === "string" && (
                        <p className="font-size-small">
                          {_clinicData.locality}
                        </p>
                      )}
                    </div>
                    <img
                      data-cy={`remove-connected-clinic-${clinicId}`}
                      className="margin-left-auto"
                      alt="remove"
                      src={cancel_icon}
                      onClick={(event) => {
                        event.stopPropagation();
                        props.removeConnectedClinic(clinicId);
                      }}
                    />
                  </article>
                );
              })}
              <p className="text-align-center inherit-parent-width margin-top-large padding-medium padding-bottom-small font-color-secondary font-weight-normal font-family-gilroy-regular font-size-small">
                Connected clinics provides access to view and upload new records
                to the patient’s timeline.
              </p>
            </>
          ) : (
            <>
              {!isValidArray(requestedClinics()) && (
                <div className="text-align-center inherit-parent-height flex-center-children-vertically max-width-500px margin-horizontal-auto">
                  <div>
                    <img
                      src={noConnectedClinicsFallBack}
                      alt="empty-doc"
                      width="100%"
                      height="150px"
                      className="padding-default"
                    />
                    <div className="font-family-gilroy-bold font-size-medium font-color-secondary padding-default">
                      No connections found.
                    </div>
                    <div className="font-family-gilroy-medium font-size-medium font-color-tertiary padding-default">
                      Visit a ninto partnered clinic and connect by approaching
                      their front desk
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

const ClinicConnectionsSuspense = () => {
  return (
    <article className="screen-loader-wrapper flex-center-children-horizontally z-index-1">
      <main className="padding-medium max-width-588px inherit-parent-width ">
        <div className="inherit-parent-width flex-justify-content-center ">
          <div className="shimmer padding-default margin-default border-radius-default width-50percentage" />
        </div>
        {[...Array(3).keys()].map((index) => {
          return (
            <section className="flex-center-children-vertically " key={index}>
              <div className="shimmer padding-larger margin-default border-radius-100-percentage" />
              <div className="width-80percentage">
                <div className="shimmer padding-small margin-default border-radius-default width-80percentage" />
                <div className="shimmer padding-small margin-default border-radius-default width-30percentage" />
              </div>
            </section>
          );
        })}
        <div className="margin-top-default inherit-parent-width flex-justify-content-center ">
          <div className="shimmer padding-default margin-default border-radius-default width-50percentage" />
        </div>
        {[...Array(5).keys()].map((index) => {
          return (
            <section className="flex-center-children-vertically " key={index}>
              <div className="shimmer padding-larger margin-default border-radius-100-percentage" />
              <div className="width-80percentage">
                <div className="shimmer padding-small margin-default border-radius-default width-80percentage" />
                <div className="shimmer padding-small margin-default border-radius-default width-30percentage" />
              </div>
            </section>
          );
        })}
      </main>
    </article>
  );
};

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    profile: state.profile,
    downloads: state.downloads,
    clinics: state.clinics
  };
};

const mapDispatchToProps = function () {
  return {
    logout: () => logout(),
    editProfileRequest: (data, currentField) =>
      editProfileRequest(data, currentField),
    setProfilePicture: (profilePicture) => setProfilePicture(profilePicture),
    removeConnectedClinic: (clinicId) => removeConnectedClinic(clinicId),
    removeClinicRequest: (requestDocumentId) =>
      removeClinicRequest(requestDocumentId)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
