import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import Header from "../../front-end-global-components/components/Header/Header";
import Button from "../../front-end-global-components/components/Button/Button";
import PDFRenderer from "../../front-end-global-components/components/PDFRenderer/PDFRenderer";
import { useParams } from "react-router-dom";
import "./ViewDocument.css";
import { isValidObject } from "../../services/validators";
import documentNotFoundImg from "../../assets/images/documentNotFoundFallBack.svg";
import "../../front-end-global-components/design-system.css";
// import addIconGrey from "../../front-end-global-components/assets/addIconGrey.svg";
import {
  downloadURI,
  validValue
} from "../../front-end-global-components/utils/Functions";
import { throwError } from "../../services/error";
import FHIRRenderer from "../../front-end-global-components/components/FHIRRenderer/FHIRRenderer";
import { setErrorStatus } from "../../redux/status/actions";
import { getDocumentDownloadUrl } from "../../redux/downloads/actions";
import { selectCurrentDocument } from "../../redux/documents/actions";

const ViewDocument = (props) => {
  let documentType = window.location.pathname.split("/")[1];
  const [isMounted, setIsMounted] = useState(false);
  const [pdfArrayBuffer, setPdfArrayBuffer] = useState({});
  const [currentDoc, setCurrentDoc] = useState("base");
  let { documentID } = useParams();
  const [readOnly, setReadOnly] = useState(true);
  const [PDFfromFHIR, setPDFfromFHIR] = useState(null);
  const [fileType, setFileType] = useState("");
  const [jsonData, setJsonData] = useState(null);
  const isAddNotesModeOn = useRef(false);
  const [isPdfLoading, setIsPdfLoading] = useState(false);

  const getPdfData = (doctorId) => {
    if (!!doctorId && !!pdfArrayBuffer[doctorId]) {
      return;
    } else if (!doctorId && !!pdfArrayBuffer.base) {
      return;
    }
    if (
      !!doctorId &&
      isValidObject(props.downloads.data) &&
      isValidObject(props.downloads.data[documentType]) &&
      isValidObject(props.downloads.data[documentType][documentID]) &&
      typeof props.downloads.data[documentType][documentID].downloadUrl ===
        "string" &&
      isValidObject(props.downloads.data[documentType][documentID].notes) &&
      typeof props.downloads.data[documentType][documentID].notes[doctorId] ===
        "string"
    ) {
      fetch(
        props.downloads.data[documentType][documentID].notes[doctorId]
      ).then(async (res) => {
        setPdfArrayBuffer({
          ...pdfArrayBuffer,
          [doctorId]: await res.arrayBuffer()
        }).catch((error) => {
          setErrorStatus({
            custom: "custom",
            message: "Unable to download"
          });
          console.error(error);
        });
        setCurrentDoc(doctorId);
      });
    } else if (
      isValidObject(props.documents.data) &&
      Object.values(props.documents.data)
        .flat()
        .some((data) => data.documentId === documentID) &&
      isValidObject(props.downloads.data) &&
      isValidObject(props.downloads.data[documentType][documentID]) &&
      typeof props.downloads.data[documentType][documentID].downloadUrl ===
        "string"
    ) {
      fetch(props.downloads.data[documentType][documentID].downloadUrl)
        .then(async (res) => {
          const url = res.url.split("?")[0];
          const lastElement = url.split(".").length - 1;
          setFileType(url.split(".")[lastElement]);
          if (url.split(".")[lastElement] === "json") {
            const response = await fetch(res.url, {
              method: "GET"
            });
            const text = await response.json();
            setJsonData(text);
          } else {
            setPdfArrayBuffer({
              ...pdfArrayBuffer,
              base: await res.arrayBuffer()
            });
          }
        })
        .catch((error) => {
          setErrorStatus({
            custom: "custom",
            message: "Unable to download"
          });
          console.error(error);
        });
    }
  };

  useEffect(() => {
    const downloadUrl = validValue(
      () => props.downloads.data[documentType][documentID].downloadUrl
    );
    if (
      typeof downloadUrl === "string" &&
      !Object.keys(pdfArrayBuffer).includes("base")
    ) {
      getPdfData();
    }
    // eslint-disable-next-line
  }, [props.downloads.data]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isValidObject(props.documents.data)) {
      const selectedDocument = Object.values(props.documents.data).find(
        (data) => data.documentId === documentID
      );

      if (isValidObject(selectedDocument)) {
        props.selectCurrentDocument(selectedDocument);
      }
    }

    // eslint-disable-next-line
  }, [documentID, props.auth.data.accessToken]);

  useEffect(() => {
    if (isAddNotesModeOn.current === true) {
      if (readOnly === true) {
        setReadOnly(false);
      }
    } else {
      if (readOnly === false) {
        setReadOnly(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfArrayBuffer]);

  useEffect(() => {
    if (
      isValidObject(props.documents.currentDocument) &&
      typeof documentType === "string"
    ) {
      props.getDocumentDownloadUrl(documentType, documentID);
    }
    // eslint-disable-next-line
  }, [props.documents.currentDocument, documentType]);

  const headerTitle = () => {
    switch (documentType) {
      case "prescriptions":
        return "View Prescription";

      case "receipts":
        return "View Receipt";

      case "reports":
        return "View Report";

      case "admissions":
        return "View Admission";

      case "insurances":
        return "View Insurance";

      case "vaccine":
        return "View Vaccine";

      case "consultation":
        return "View Consultation";

      case "vital":
        return "View Vital";

      case "WellnessRecord":
        return "View WellnessRecord";

      default:
        return "View Document";
    }
  };

  const shouldShowSuspense = () => {
    if (
      isValidObject(props.documents.currentDocument) &&
      isValidObject(props.downloads.data) &&
      typeof documentType === "string" &&
      isValidObject(props.downloads.data[documentType]) &&
      isValidObject(props.downloads.data[documentType][documentID]) &&
      typeof props.downloads.data[documentType][documentID].downloadUrl ===
        "string"
    ) {
      return false;
    } else {
      return true;
    }
  };

  const shouldShowFallBack = () => {
    if (
      isMounted === true &&
      isValidObject(props.documents.currentDocument) &&
      shouldShowSuspense() === true &&
      props.documents.loading === false &&
      props.downloads.loading === false
    ) {
      return true;
    } else {
      return false;
    }
  };

  // const onAddNotesClick = () => {
  //   const doctorId = Object.keys(props.profile.data)[0];
  //   if (isAddNotesModeOn.current === false) {
  //     if (!Object.keys(pdfArrayBuffer).includes(doctorId)) {
  //       getPdfData(doctorId);
  //       // setCurrentDoc(doctorId);
  //     } else {
  //       setReadOnly(false);
  //       // setCurrentDoc(doctorId);
  //     }
  //   }

  //   if (isAddNotesModeOn.current === true) {
  //     isAddNotesModeOn.current = false;
  //   } else {
  //     isAddNotesModeOn.current = true;
  //   }
  // };

  const shouldShowPdf = () => {
    let result = false;
    const _downloadUrl = validValue(
      () => props.downloads.data[documentType][documentID].downloadUrl
    );

    const _currentDocumentData = isValidObject(props.documents.currentDocument);

    if (typeof _downloadUrl === "string" && _currentDocumentData) {
      result = true;
    } else {
      result = false;
    }
    return result;
  };

  useEffect(() => {
    return () => {
      setPdfArrayBuffer({});
    };
  }, []);

  return (
    <div className="inherit-parent-height">
      <Header
        title={headerTitle()}
        backButtonOnClick={() => {
          if (PDFfromFHIR !== null) {
            setPDFfromFHIR(null);
          } else {
            props.navigate(-1);
          }
        }}
        showShare={
          isValidObject(pdfArrayBuffer) &&
          props.downloads.loading === false &&
          typeof props.documents.currentDocument?.name === "string"
            ? true
            : false
        }
        supportIconOnClick={() => props.navigate("/queries")}
        shareIconOnClick={async () => {
          try {
            if (
              typeof props.documents.currentDocument?.name === "string" &&
              pdfArrayBuffer[currentDoc]
            ) {
              const blob = new Blob([pdfArrayBuffer[currentDoc]], {
                type: "application/pdf"
              });
              const documentFile = new File(
                [blob],
                props.documents.currentDocument.name,
                { type: "application/pdf" }
              );
              if (
                !window.Cypress &&
                !!navigator &&
                navigator.canShare &&
                navigator.canShare({ files: [documentFile] })
              ) {
                await navigator.share({
                  title: "Ninto",
                  text: "Ninto - View Document",
                  url: window.location.href,
                  files: [documentFile]
                });
              } else {
                downloadURI(
                  pdfArrayBuffer[currentDoc],
                  props.documents.currentDocument.name
                );
              }
            }
          } catch (error) {
            throw throwError("custom", "Something went wrong");
          }
        }}
      />
      <article className="remaining-body-height overflow-x-scroll hide-scroll-bar flex-direction-column overflow-auto ">
        {shouldShowFallBack() === true ? (
          <DocumentNotFound navigate={props.navigate} />
        ) : shouldShowPdf() === true ? (
          <>
            {props.documents.currentDocument && (
              <>
                <section
                  data-cy="documents-view-screen"
                  className={` inherit-parent-height overflow-auto display-flex flex-direction-column inherit-parent-width background-white`}
                >
                  {(fileType !== "json" || PDFfromFHIR !== null) && (
                    <PDFRenderer
                      readOnly={readOnly}
                      pdfArrayBuffer={
                        PDFfromFHIR
                          ? PDFfromFHIR
                          : pdfArrayBuffer[currentDoc]
                          ? pdfArrayBuffer[currentDoc]
                          : null
                      }
                      isPrescription={
                        documentType === "Prescriptions" ||
                        documentType === "admissions"
                      }
                      saveWithBackground={false}
                      loading={props.documents.loading}
                      addNotes={props.addNotes}
                      onPDFChange={() => {
                        if (isPdfLoading === false) {
                          setIsPdfLoading(true);
                        }
                      }}
                      onRender={() => {
                        if (isPdfLoading === true) {
                          setIsPdfLoading(false);
                        }
                      }}
                    />
                  )}
                  {fileType === "json" &&
                    jsonData !== null &&
                    PDFfromFHIR === null && (
                      <FHIRRenderer
                        document={props.documents.currentDocument}
                        userType="doctor"
                        connection={props.connection}
                        data={jsonData}
                        documentOnClick={(document) => {
                          setPDFfromFHIR(document);
                        }}
                      />
                    )}
                </section>
                {/* <PdfNotes
                  showAddNotes={true}
                  addNotesOnClick={() => {
                    onAddNotesClick();
                  }}
                  loading={
                    (isValidObject(props.documents.currentDocument?.notes) &&
                      !pdfArrayBuffer[currentDoc]) ||
                    isPdfLoading
                  }
                  document={props.documents.currentDocument}
                  show={
                    readOnly === true && documentType === "reports"
                      ? true
                      : false
                  }
                  currentNotesId={currentDoc !== "base" ? currentDoc : ""}
                  notesButtonOnClick={(notesId) => {
                    if (currentDoc === notesId) {
                      if (!Object.keys(pdfArrayBuffer).includes("base")) {
                        getPdfData();
                      }
                      setCurrentDoc("base");
                    } else {
                      if (!Object.keys(pdfArrayBuffer).includes(notesId)) {
                        getPdfData(notesId);
                      }
                      setCurrentDoc(notesId);
                    }
                  }}
                /> */}
              </>
            )}
          </>
        ) : (
          <ViewDocumentSuspense
            showNotesSuspense={
              props.documents.currentDocument &&
              isValidObject(props.documents.currentDocument) &&
              props.documents.currentDocument.notes
                ? true
                : false
            }
          />
        )}
      </article>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    profile: state.profile,
    documents: state.documents,
    downloads: state.downloads
  };
};

const mapDispatchToProps = function () {
  return {
    selectCurrentDocument: (documentData) =>
      selectCurrentDocument(documentData),
    getDocumentDownloadUrl: (documentType, documentId) =>
      getDocumentDownloadUrl(documentType, documentId)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewDocument);

function DocumentNotFound(props) {
  return (
    <section
      className=" inherit-parent-height inherit-parent-width flex-place-children-page-center padding-larger"
      data-cy="document-not-found-fall-back"
    >
      <main className="flex-place-children-page-center">
        <img src={documentNotFoundImg} alt="querySubmittedSuccess" />
        <h1 className="font-weight-normal font-family-gilroy-bold font-size-medium font-color-secondary padding-top-large padding-bottom-large">
          Document not found
        </h1>
        <p className="font-family-gilroy-medium font-size-medium font-color-tertiary text-align-center">
          Please try again later or create query
        </p>
        <div className="inherit-parent-width max-width-350px">
          <Button
            data-cy="redirect-button"
            type="submit"
            text="Okay"
            className="inherit-parent-width margin-top-larger"
            onClick={(_) => {
              props.navigate(-1);
            }}
            boxShadow={false}
          />
        </div>
      </main>
    </section>
  );
}

function ViewDocumentSuspense(props) {
  return (
    <article
      data-cy="view-document-suspense"
      className="screen-loader-wrapper inherit-parent-width  flex-center-children-horizontally"
    >
      <main className="inherit-parent-width padding-medium ">
        <main className="inherit-parent-width padding-medium ">
          <ul className="margin-top-larger margin-bottom-larger list-style-none">
            <li
              className="padding-right-large shimmer margin-bottom-default"
              style={{ width: "80%", height: "2vh" }}
            ></li>
            <li
              className="padding-right-large shimmer margin-bottom-default"
              style={{ width: "90%", height: "2vh" }}
            ></li>
            <li
              className="padding-right-large shimmer margin-bottom-default"
              style={{ width: "70%", height: "2vh" }}
            ></li>
          </ul>
          <ul className="margin-top-larger margin-bottom-larger padding-vertical-larger list-style-none">
            <li
              className="padding-right-large shimmer margin-bottom-default"
              style={{ width: "80%", height: "2vh" }}
            ></li>
            <li
              className="padding-right-large shimmer margin-bottom-default"
              style={{ width: "90%", height: "2vh" }}
            ></li>
            <li
              className="padding-right-large shimmer margin-bottom-default"
              style={{ width: "70%", height: "2vh" }}
            ></li>
          </ul>
          <ul className="margin-top-larger margin-bottom-larger list-style-none">
            <li
              className="padding-right-large shimmer margin-bottom-default"
              style={{ width: "80%", height: "2vh" }}
            ></li>
            <li
              className="padding-right-large shimmer margin-bottom-default"
              style={{ width: "90%", height: "2vh" }}
            ></li>
            <li
              className="padding-right-large shimmer margin-bottom-default"
              style={{ width: "70%", height: "2vh" }}
            ></li>
          </ul>
        </main>
        {props.showNotesSuspense === true && (
          <section className="inherit-parent-width flex-place-children-page-center height-10vh ">
            <div className="shimmer padding-larger margin-default border-radius-default width-80percentage" />
          </section>
        )}
      </main>
    </article>
  );
}

//pdf notes component
// const PdfNotes = (props) => {
//   /**
//    * loading => to show suspense
//    * notesButtonOnClick => onClick fun callback of notes button
//    * document => document data object
//    * currentNotesId => selected notes id
//    * addNotesOnClick => add notes button onClick fun
//    * showAddNotes => to show add notes button  `true` or `false`
//    */
//   return props.loading === true ? (
//     <PdfNotesSuspense {...props} />
//   ) : (
//     props.show === true && isValidObject(props.document) && (
//       <section
//         className={`background-color-secondary margin-horizontal-auto inherit-parent-width padding-left-large padding-right-large padding-bottom-large footer background-color-grey inherit-parent-width padding-bottom-small`}
//         data-cy="doctors-notes-dropdown"
//       >
//         <p className="font-family-gilroy-regular font-color-secondary font-size-smaller padding-top-default">
//           Notes by doctors
//         </p>
//         <div className=" inherit-parent-width flex-justify-content-space-between">
//           <div
//             className={`${
//               props.showAddNotes === true
//                 ? "width-85-percentage"
//                 : "inherit-parent-width "
//             } border-radius-default overflow-x-auto padding-top-default padding-bottom-default overflow-x-auto inherit-parent-height display-flex flex-align-items-center`}
//           >
//             {isValidObject(props.document.notes) ? (
//               Object.keys(props.document.notes).map((doctorId, index) => {
//                 return (
//                   <React.Fragment key={doctorId}>
//                     <button
//                       key={index}
//                       className={`white-space-nowrap padding-top-small padding-bottom-small padding-left-default padding-right-default margin-right-small border-radius-default font-family-gilroy-regular  font-size-small
//                                      ${
//                                        doctorId === props.currentNotesId
//                                          ? "background-primary font-color-white"
//                                          : "background-color-secondary font-color-secondary border-1px-e5e5e5"
//                                      }`}
//                       onClick={() => {
//                         if (typeof props.notesButtonOnClick === "function") {
//                           //returns notes id
//                           props.notesButtonOnClick(doctorId);
//                         }
//                       }}
//                       data-cy={
//                         props.document.notes[doctorId].authorName &&
//                         `notes-by-${props.document.notes[doctorId].authorName}`
//                       }
//                     >
//                       {props.document.notes[doctorId].authorName &&
//                         props.document.notes[doctorId].authorName}
//                     </button>
//                   </React.Fragment>
//                 );
//               })
//             ) : (
//               <div>No notes available</div>
//             )}
//           </div>
//           {props.showAddNotes === true && (
//             <button
//               data-cy="add-notes-button"
//               className="background-color-lite-grey border-radius-default pdf-add-notes-button-size flex-place-children-page-center cursor-pointer"
//               onClick={(event) => {
//                 if (typeof props.addNotesOnClick === "function") {
//                   props.addNotesOnClick(event);
//                 }
//               }}
//             >
//               <img src={addIconGrey} alt="" className="add-notes-icon-size" />
//             </button>
//           )}
//         </div>
//       </section>
//     )
//   );
// };

// function PdfNotesSuspense(props) {
//   return (
//     <section
//       className="background-color-secondary margin-horizontal-auto inherit-parent-width padding-left-large padding-right-large  inherit-parent-width padding-bottom-large"
//       data-cy="pdf-notes-suspense"
//     >
//       <div
//         className="shimmer border-radius-default padding-default"
//         style={{ width: "20%" }}
//       ></div>
//       <div
//         className={`display-flex flex-justify-content-space-between ${
//           props.showAddNotes !== true ? "padding-top-default" : ""
//         }`}
//         style={{ alignItems: "flex-end" }}
//       >
//         <div
//           className={`display-flex ${
//             props.showAddNotes === true
//               ? "width-85-percentage"
//               : "inherit-parent-width "
//           }`}
//           style={{ height: "30%" }}
//         >
//           <span
//             className="shimmer padding-default margin-right-large border-radius-default"
//             style={{ width: "100%" }}
//           ></span>
//         </div>
//         {props.showAddNotes === true && (
//           <div className="shimmer pdf-add-notes-button-size border-radius-default"></div>
//         )}
//       </div>
//     </section>
//   );
// }
