import React from "react";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import { isValidObject } from "../../front-end-global-components/services/validators";
import noPinnedPatientSvg from "../../assets/images/noPinnedPatient.svg";
import { isValidArray } from "../../services/validators";

function PinnedPatientsTimeLine(props) {
  const hasPinnedPatients =
    isValidObject(props.followingPatients) ||
    isValidObject(props.pinnedPatients);

  const sortedPatients = (patients) => {
    let result = [];
    if (isValidObject(patients)) {
      let pinnedPatientListForSorting = [];
      let allHasSuccessivePinnedPatient = true;
      // successivePatient : PID
      let successivePatientAsKeyPidAsValue = {};

      for (const key in patients) {
        pinnedPatientListForSorting.push(patients[key]);
        if (patients[key].successivePatient === undefined) {
          successivePatientAsKeyPidAsValue[key] = null;
          allHasSuccessivePinnedPatient = false;
        } else if (patients[key].successivePatient === null) {
          successivePatientAsKeyPidAsValue["lastPinnedPatient"] = key;
        } else if (typeof patients[key].successivePatient === "string") {
          successivePatientAsKeyPidAsValue[patients[key].successivePatient] =
            key;
        }
      }

      if (allHasSuccessivePinnedPatient === false) {
        // First sort based on timestamp
        const sortedArray = pinnedPatientListForSorting.sort(
          (firstObject, secondObject) =>
            firstObject.timestamp - secondObject.timestamp
        );
        for (let index = 0; index < sortedArray.length; index++) {
          //last patient
          if (sortedArray[index + 1] === undefined) {
            result.push({
              ...sortedArray[index],
              successivePatient: null
            });
          } else {
            result.push({
              ...sortedArray[index],
              successivePatient: sortedArray[index + 1]["patient"]["patientId"]
            });
          }
        }
      } else {
        result = [];
        let _index = 0;
        // eslint-disable-next-line no-unused-vars
        for (const key in successivePatientAsKeyPidAsValue) {
          if (!result[0]) {
            result.push(
              patients[successivePatientAsKeyPidAsValue["lastPinnedPatient"]]
            );
          } else {
            const nextPatientPid =
              successivePatientAsKeyPidAsValue[
                result[_index - 1]["patient"]["patientId"]
              ];
            result.push(patients[nextPatientPid]);
          }
          _index = _index + 1;
        }
        result.reverse();
      }
    }

    return result;
  };

  return (
    <>
      <div className="inherit-parent-width  remaining-height-actions-header overflow-y-auto ">
        {props.patients.loading === true ? (
          <PatientTimeLineSuspense />
        ) : (
          <>
            <div className="inherit-parent-width display-flex flex-justify-content-center padding-medium font-weight-normal font-family-gilroy-regular font-size-small ">
              {props.isDoctorsPinned === true
                ? "FOLLOWING PATIENTS"
                : "PINNED PATIENTS"}
            </div>
            {hasPinnedPatients === true ? (
              <section
                data-cy="pinned-patients-timeline"
                className="padding-horizontal-medium padding-top-smaller"
              >
                {props.isDoctorsPinned === true &&
                  isValidObject(props.followingPatients) && (
                    <>
                      {isValidArray(sortedPatients(props.followingPatients)) &&
                        sortedPatients(props.followingPatients).map(
                          (patientData) => {
                            return (
                              <article
                                className="cursor-pointer padding-vertical-medium font-size-medium flex-start flex-align-items-center font-color-secondary"
                                key={`doctor-pinned-patient-${patientData.patient.patientId}`}
                                onClick={() => {
                                  if (
                                    typeof props.onPinnedPatientClicked ===
                                    "function"
                                  ) {
                                    props.onPinnedPatientClicked(
                                      patientData.patient.patientId
                                    );
                                  }
                                }}
                                data-cy={`following-${patientData.patient.patientId}`}
                              >
                                <RoundedProfilePicture
                                  size="medium"
                                  loading={false}
                                  onClick={() => {}}
                                  src={
                                    typeof props.pinnedPatientProfileImageSrc ===
                                    "function"
                                      ? props.pinnedPatientProfileImageSrc(
                                          patientData.patient.patientId
                                        )
                                      : null
                                  }
                                  data-cy={"pinned-patient-profile-image"}
                                />
                                <div className="margin-horizontal-large">
                                  <h5 className="font-weight-normal font-family-gilroy-medium font-size-medium font-weight-normal ">
                                    {patientData.patient.fullName}
                                  </h5>
                                  <p className="font-size-small">
                                    {patientData.patient.phoneNumber
                                      ? patientData.patient.phoneNumber
                                      : patientData.patient.healthId
                                      ? patientData.patient.healthId
                                      : ""}
                                  </p>
                                </div>
                              </article>
                            );
                          }
                        )}
                    </>
                  )}

                {props.isDoctorsPinned === false &&
                  sortedPatients(props.pinnedPatients).map(
                    (patientData, index) => {
                      // if(patientData.permanent === false){}
                      const patientID =
                        patientData?.patient?.patientId &&
                        patientData.patient.patientId;
                      return (
                        <article
                          className="cursor-pointer padding-vertical-medium flex-center-children-vertically font-size-medium flex-start font-color-secondary"
                          key={`clinic-pinned-patient-${index}`}
                          onClick={() => {
                            props.onPinnedPatientClicked &&
                              props.onPinnedPatientClicked(patientID);
                          }}
                          data-cy={`pinned-${patientID}`}
                        >
                          <RoundedProfilePicture
                            size="medium"
                            loading={false}
                            onClick={() => {}}
                            src={
                              typeof props.pinnedPatientProfileImageSrc ===
                              "function"
                                ? props.pinnedPatientProfileImageSrc(patientID)
                                : null
                            }
                            data-cy={"pinned-patient-profile-image"}
                          />
                          <div className="margin-horizontal-large">
                            <h5 className="font-weight-normal font-family-gilroy-medium font-size-medium font-weight-normal ">
                              {patientData.patient.fullName}
                            </h5>
                            <p className="font-size-small">
                              {patientData.patient.phoneNumber
                                ? patientData.patient.phoneNumber
                                : patientData.patient.healthId
                                ? patientData.patient.healthId
                                : ""}
                            </p>
                          </div>
                        </article>
                      );
                    }
                  )}
              </section>
            ) : (
              <div className="remaining-height-actions-header inherit-parent-width">
                <section
                  data-cy="no-pinned-patients-fall-back-section"
                  className="inherit-parent-height inherit-parent-width flex-place-children-page-center"
                >
                  <img src={noPinnedPatientSvg} alt="hh" />
                  <h1 className="font-weight-normal font-family-gilroy-bold font-size-medium font-color-secondary padding-top-medium">
                    You have no pinned patients
                  </h1>
                  <p className="font-family-gilroy-medium font-size-medium text-align-center font-color-secondary padding-larger padding-top-medium">
                    Get started by pinning the patients you want to follow the
                    timeline of or your clinic can pin them for you.
                  </p>
                </section>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

const PatientTimeLineSuspense = () => {
  return (
    <article className="screen-loader-wrapper flex-center-children-horizontally z-index-1">
      <main className="padding-medium max-width-588px inherit-parent-width ">
        <div className="inherit-parent-width flex-justify-content-center ">
          <div className="shimmer padding-default margin-default border-radius-default width-50percentage" />
        </div>
        {[...Array(10).keys()].map((index) => {
          return (
            <section className="flex-center-children-vertically " key={index}>
              <div className="shimmer padding-larger margin-default border-radius-100-percentage" />
              <div className="width-80percentage">
                <div className="shimmer padding-small margin-default border-radius-default width-80percentage" />
                <div className="shimmer padding-small margin-default border-radius-default width-30percentage" />
              </div>
            </section>
          );
        })}
      </main>
    </article>
  );
};

export default PinnedPatientsTimeLine;
