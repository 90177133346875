import React, { useCallback, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import "./Home.css";
import {
  isValidArray,
  isValidObject,
  isValidString
} from "../../services/validators";
import {
  isProfileCompleted,
  validProfileObject,
  validValue
} from "../../services/utils";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import Header from "../../front-end-global-components/components/Header/Header";
import completeYourProfileFallBack from "../../assets/images/completeYourProfileFallBack.svg";
import Button from "../../front-end-global-components/components/Button/Button";
import ActionsHeader from "../../components/ActionsHeader/ActionsHeader";
import PinnedPatientsTimeLine from "../../components/PinnedPatientsTimeLine/PinnedPatientsTimeLine";
import NotificationTimeLine from "../../components/NotificationTimeLine/NotificationTimeLine";
import ClinicSwitcherModal from "../../components/ClinicSwitcherModal/ClinicSwitcherModal";
import { CSSTransition } from "react-transition-group";
import noConnectedClinicsFallBack from "../../assets/images/noConnectedClinicsFallBack.svg";
import {
  clearPatientSearchData,
  findPatient
} from "../../redux/abdmPatients/actions";
import { getPatientsImageDownloadUrl } from "../../redux/downloads/actions";

function Home(props) {
  const [showModal, setShowModal] = useState(false);
  const [showProfileCompleteTile, setShowProfileCompleteTile] = useState(false);
  const searchResultElementRef = useRef(null);
  const [isSearchOn, setIsSearchOn] = useState(false);
  const [currentSelectedAction, setCurrentSelectedAction] =
    useState("PINNED_PATIENTS");

  const checkProfileCompletion = useCallback(() => {
    let shouldRedirectToProfileComplete = false;
    if (isValidObject(props.profile.data)) {
      const dataDoc = Object.values(props.profile.data)[0];
      if (
        isValidObject(dataDoc) &&
        !isValidObject(dataDoc.aadhar) &&
        typeof dataDoc.proof !== "string"
      ) {
        // No Data
        shouldRedirectToProfileComplete = true;
      } else if (dataDoc && !dataDoc.registrationDetails) {
        shouldRedirectToProfileComplete = true;
      } else if (dataDoc && dataDoc.public && !dataDoc.public.education) {
        shouldRedirectToProfileComplete = true;
      }

      if (dataDoc && shouldRedirectToProfileComplete) {
        if (!showProfileCompleteTile) setShowProfileCompleteTile(true);
      } else {
        if (showProfileCompleteTile) setShowProfileCompleteTile(false);
      }
    }
    return shouldRedirectToProfileComplete;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.profile.data]);

  const isEditProfileCompleted = () => {
    if (validProfileObject(props.profile.data)) {
      const uid = props?.profile?.data && Object.keys(props.profile.data)[0];
      if (
        isValidString(props.profile.data?.[uid]?.email) &&
        typeof props.profile.data?.[uid]?.dateOfBirth === "number" &&
        isValidString(props.profile.data?.[uid]?.public.city) &&
        isValidObject(props.profile.data?.[uid]?.public.experience) &&
        isValidString(props.profile.data?.[uid]?.public.gender) &&
        isValidArray(props.profile.data?.[uid]?.public.speciality)
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    if (props.auth.data.accessToken !== null) {
      checkProfileCompletion();
    }
    return () => {};
  }, [props.auth.data.accessToken, checkProfileCompletion, props.profile.data]);

  useEffect(() => {
    if (currentSelectedAction !== "NOTIFICATIONS") {
      // props.setNotificationPaginationNumber();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedAction]);

  const showSuspense = () => {
    if (
      props.auth.loading === true ||
      props.clinics.loading === true ||
      props.profile.loading === true
    ) {
      return true;
    } else {
      return false;
    }
  };

  const doctorProfilePictureSrc = () => {
    if (
      isValidObject(props.profile.data) &&
      isValidObject(props.profile.data[Object.keys(props.profile.data)])
    ) {
      const [userDocId] = Object.keys(props.profile.data);
      if (
        isValidObject(props.downloads.data) &&
        isValidObject(props.downloads.data.profilePictures) &&
        isValidObject(props.downloads.data.profilePictures.doctors) &&
        typeof props.downloads.data.profilePictures.doctors[userDocId] ===
          "string"
      ) {
        return props.downloads.data.profilePictures.doctors[userDocId];
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const clinicsNotificationsCount = (clinicId) => {
    if (
      typeof clinicId === "string" &&
      isValidObject(props.notifications.data)
    ) {
      let clinicNotificationCount = 0;
      if (isValidArray(props.notifications.data)) {
        clinicNotificationCount = props.notifications.data.filter(
          (notification) => notification.read === false
        ).length;
      }
      return clinicNotificationCount;
    } else {
      return null;
    }
  };

  const isShowSearchResults = () => {
    if (
      (isSearchOn === true &&
        validProfileObject(props.profile.data) &&
        props.abdmPatients.loading === true) ||
      (validProfileObject(props.profile.data) &&
        props.abdmPatients.searchKey &&
        props.abdmPatients.searchResults)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const clinicNameAndLocality = () => {
    if (
      isProfileCompleted(props.profile.data) &&
      isValidObject(props.clinics.data) &&
      typeof props.clinics.currentClinic === "string" &&
      isValidObject(props.clinics.data[props.clinics.currentClinic])
    ) {
      return {
        clinicName: props.clinics.data[props.clinics.currentClinic].clinicName,
        locality: props.clinics.data[props.clinics.currentClinic].locality
      };
    } else {
      return null;
    }
  };

  const onCompleteYourProfileTileClick = () => {
    if (checkProfileCompletion()) {
      // Registration - Education - Proof has been completed
      props.navigate("/profilecompletion");
    } else if (isEditProfileCompleted()) {
      props.navigate("/editprofile");
    }
  };

  const pinnedPatientProfileImageSrc = (patientId) => {
    const pinnedPatientData = validValue(
      () => props.abdmPatients.pinned[props.clinics.currentClinic][patientId]
    );
    const pinnedPatientProfilePicture = validValue(
      () => props.downloads.data.profilePictures.patients[patientId]
    );
    if (
      typeof props.clinics.currentClinic === "string" &&
      pinnedPatientData !== false &&
      typeof pinnedPatientProfilePicture === "string"
    ) {
      return pinnedPatientProfilePicture;
    } else {
      return null;
    }
  };

  const followingPatientProfileImageSrc = (patientId) => {
    const followingPatientData = validValue(
      () => props.abdmPatients.following[props.clinics.currentClinic][patientId]
    );
    const followingPatientProfilePicture = validValue(
      () => props.downloads.data.profilePictures.patients[patientId]
    );
    if (
      typeof props.clinics.currentClinic === "string" &&
      followingPatientData !== false &&
      typeof followingPatientProfilePicture === "string"
    ) {
      return followingPatientProfilePicture;
    } else {
      return null;
    }
  };

  const connectedClinicProfileImage = (clinicUid) => {
    const clinicProfilePicture = validValue(
      () => props.downloads.data.profilePictures.clinics[clinicUid]
    );
    if (typeof clinicProfilePicture === "string") {
      return clinicProfilePicture;
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (
      isSearchOn === false &&
      props.abdmPatients.searchResults &&
      Object.values(props.abdmPatients.searchResults).length > 0
    ) {
      props.clearPatientSearchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchOn]);

  return (
    <div className="inherit-parent-height">
      <Header
        inputType="text"
        userType="doctor"
        primary
        onProfilePictureClick={() => {
          onCompleteYourProfileTileClick();
        }}
        searchResults={{
          ...(props.abdmPatients.searchResults &&
            props.abdmPatients.searchResults)
        }}
        // searchPrefix={"+91"}
        searchPlaceHolder={"Health Id / Mobile"}
        clinicNameAndLocality={clinicNameAndLocality()}
        userProfilePictureSrc={doctorProfilePictureSrc()}
        onSearchUser={(searchedValue) => {
          console.log(searchedValue, "searchedValue");
          if (searchedValue) {
            props.findPatient(searchedValue, "healthId");
          }
        }}
        removeSearchResults={(searchKeyword) => {
          searchKeyword
            ? props.clearPatientSearchData()
            : props.clearPatientSearchData();
        }}
        loading={showSuspense()}
        notificationsCount={validValue(() => props.clinics.requests.length)}
        showSearch={props.clinics.currentClinic ? true : false}
        onSearchOn={(foo) => {
          setIsSearchOn(foo);
        }}
        isSearchOn={isSearchOn}
        // searchType="phoneNumber"
        supportIconOnClick={() => {
          props.navigate("/queries");
        }}
        searchResultElementRef={searchResultElementRef}
        searchKeyword={
          props.abdmPatients.searchKey ? props.abdmPatients.searchKey : null
        }
        profile-picture-data-cy="profile-image"
      />

      {isShowSearchResults() ? (
        <>
          {props.abdmPatients.loading === true ? (
            <PatientProfileSearchSuspense />
          ) : (
            <>
              {
                <div
                  ref={searchResultElementRef}
                  className="remaining-body-height overflow-x-scroll inherit-parent-width background-color-white z-index-1 padding-left-medium padding-right-medium padding-bottom-larger "
                >
                  {props.abdmPatients?.searchResults &&
                    Object.keys(props.abdmPatients.searchResults).map(
                      (patientId, index) => {
                        return (
                          <article key={index}>
                            <section
                              className="cursor-pointer padding-vertical-medium font-size-medium flex-start font-color-secondary flex-align-items-center"
                              key={1}
                              onClick={(event) => {
                                event.preventDefault();
                                props.navigate(`/patientProfile/${patientId}`);
                                // props.setSelectedPatient(patientId);
                              }}
                            >
                              <RoundedProfilePicture
                                size="medium"
                                loading={false}
                                onClick={() => {}}
                                // src={searchedPatientProfileSrc(pid)}
                                data-cy={"searched-patient-profile-image"}
                              />
                              <div className="margin-horizontal-large">
                                <h5 className="font-weight-normal font-family-gilroy-medium font-size-medium font-color-secondary font-weight-normal ">
                                  {
                                    props.abdmPatients.searchResults[patientId]
                                      .name
                                  }
                                </h5>
                                <p className="font-family-gilroy-medium font-size-small font-color-tertiary ">
                                  {props.abdmPatients.searchResults[patientId]
                                    .healthId
                                    ? props.abdmPatients.searchResults[
                                        patientId
                                      ].healthId
                                    : props.abdmPatients.searchResults[
                                        patientId
                                      ].phoneNumber}
                                </p>
                              </div>
                            </section>
                            <hr className="background-color-grey border-width-0P1-px" />
                          </article>
                        );
                      }
                    )}
                </div>
              }
            </>
          )}
        </>
      ) : (
        <>
          {showSuspense() ? (
            <HomeScreenSuspense />
          ) : (
            <main
              data-cy="home-screen"
              className="remaining-body-height overflow-x-scroll"
            >
              {(showProfileCompleteTile || !isEditProfileCompleted()) && (
                <div
                  data-cy="complete-ur-profile-tile"
                  className="padding-default inherit-parent-width inherit-parent-height flex-place-children-page-center"
                >
                  <img
                    className="display-inline-block"
                    src={completeYourProfileFallBack}
                    alt="stars_icon"
                  />
                  <h2 className="font-weight-normal font-family-gilroy-medium font-size-medium font-color-secondary padding-top-medium">
                    Please complete your profile
                  </h2>
                  <p className="font-family-gilroy-regular font-size-medium text-align-center font-color-secondary padding-top-medium padding-bottom-medium">
                    Let us get to know you better with some basic information.
                  </p>
                  <Button
                    className="max-width-150px font-family-gilroy-medium"
                    data-cy="completeNow-button"
                    text="Complete now"
                    boxShadow={false}
                    onClick={onCompleteYourProfileTileClick}
                  />
                </div>
              )}

              {!(showProfileCompleteTile || !isEditProfileCompleted()) &&
                isValidObject(props.clinics.data) && (
                  <ActionsHeader
                    loading={
                      validValue(
                        () =>
                          props.clinics.data[props.clinics.currentClinic]
                            .companyName.companyName
                      ) === false
                        ? true
                        : false
                    }
                    title={validValue(
                      () =>
                        props.clinics.data[props.clinics.currentClinic]
                          .companyName
                    )}
                    subTitle={validValue(
                      () =>
                        props.clinics.data[props.clinics.currentClinic].locality
                    )}
                    picture={connectedClinicProfileImage(
                      props.clinics.currentClinic
                    )}
                    currentSelectedAction={currentSelectedAction}
                    setCurrentSelectedAction={setCurrentSelectedAction}
                    onHeaderClick={() => {
                      setShowModal(true);
                    }}
                    requiredActions={[
                      { value: "PINNED_PATIENTS" },
                      { value: "CLINIC_PINNED" },
                      {
                        value: "NOTIFICATIONS",
                        badgeCount: clinicsNotificationsCount()
                      }
                    ]}
                  />
                )}
              {!showProfileCompleteTile &&
                isEditProfileCompleted() &&
                !isValidObject(props.clinics.data) && (
                  <div
                    data-cy="no-connected-clinics-fall-back"
                    className="text-align-center inherit-parent-height flex-center-children-vertically max-width-500px margin-horizontal-auto"
                  >
                    <div>
                      <img
                        src={noConnectedClinicsFallBack}
                        alt="empty-doc"
                        width="100%"
                        height="150px"
                        className="padding-default"
                      />
                      <div className="font-family-gilroy-bold font-size-medium font-color-secondary padding-default">
                        No connections found.
                      </div>
                      <div className="font-family-gilroy-medium font-size-medium font-color-tertiary padding-default">
                        Visit a ninto partnered clinic and connect by
                        approaching their front desk
                      </div>
                    </div>
                  </div>
                )}
              <CSSTransition
                in={currentSelectedAction === "PINNED_PATIENTS"}
                timeout={2000}
                classNames="my"
              >
                <>
                  {!(showProfileCompleteTile || !isEditProfileCompleted()) &&
                    isValidObject(props.clinics.data) &&
                    currentSelectedAction === "PINNED_PATIENTS" && (
                      <PinnedPatientsTimeLine
                        shouldShow={isProfileCompleted(props.profile.data)}
                        pinnedPatientProfileImageSrc={
                          followingPatientProfileImageSrc
                        }
                        followingPatients={validValue(
                          () => props.abdmPatients.following
                        )}
                        // pinnedPatients={validValue(
                        //   () => props.abdmPatients.pinned
                        // )}
                        isLoading={props.abdmPatients.loading}
                        onPinnedPatientClicked={(patientId) => {
                          props.navigate(`/patientProfile/${patientId}`);
                        }}
                        patients={props.abdmPatients}
                        isDoctorsPinned={true}
                      />
                    )}
                </>
              </CSSTransition>

              <CSSTransition
                in={currentSelectedAction === "CLINIC_PINNED"}
                timeout={2000}
                classNames="my"
              >
                <>
                  {!(showProfileCompleteTile || !isEditProfileCompleted()) &&
                    isValidObject(props.clinics.data) &&
                    currentSelectedAction === "CLINIC_PINNED" && (
                      <PinnedPatientsTimeLine
                        shouldShow={isProfileCompleted(props.profile.data)}
                        pinnedPatientProfileImageSrc={
                          pinnedPatientProfileImageSrc
                        }
                        pinnedPatients={props.abdmPatients.pinned}
                        isLoading={props.abdmPatients.loading}
                        onPinnedPatientClicked={(patientId) => {
                          props.navigate(`/patientProfile/${patientId}`);
                        }}
                        patients={props.abdmPatients}
                        isDoctorsPinned={false}
                      />
                    )}
                </>
              </CSSTransition>

              <CSSTransition
                in={currentSelectedAction === "NOTIFICATIONS"}
                timeout={2000}
                classNames="my"
              >
                <>
                  {currentSelectedAction === "NOTIFICATIONS" && (
                    <NotificationTimeLine navigate={props.navigate} />
                  )}
                </>
              </CSSTransition>
            </main>
          )}
        </>
      )}
      {/* <Notification show={showModal} setShow={setShowModal} /> */}
      <ClinicSwitcherModal
        navigate={props.navigate}
        show={showModal}
        setShow={setShowModal}
      />
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    abdmPatients: state.abdmPatients,
    profile: state.profile,
    clinics: state.clinics,
    downloads: state.downloads,
    notifications: state.notifications,
    patients: state.patients
  };
};

const mapDispatchToProps = function () {
  return {
    findPatient: (data, type) => findPatient(data, type),
    getPatientsImageDownloadUrl: (patientsData) =>
      getPatientsImageDownloadUrl(patientsData),
    clearPatientSearchData: () => clearPatientSearchData()
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);

function HomeScreenSuspense() {
  return (
    <article className="screen-loader-wrapper padding-top-default flex-center-children-horizontally">
      <main
        className="padding-horizontal-larger inherit-parent-width max-width-588px"
        data-cy="home-screen-suspense"
      >
        <div className="shimmer padding-medium inherit-parent-width border-radius-default margin-vertical-larger">
          <div className="padding-larger" />
        </div>

        <div className="inherit-parent-width flex-justify-content-center ">
          <div className="shimmer padding-default margin-default border-radius-default width-50percentage" />
        </div>
        {[...Array(10).keys()].map((index) => {
          return (
            <section className="flex-center-children-vertically " key={index}>
              <div className="shimmer padding-larger margin-default border-radius-100-percentage" />
              <div className="width-80percentage">
                <div className="shimmer padding-small margin-default border-radius-default width-80percentage" />
                <div className="shimmer padding-small margin-default border-radius-default width-30percentage" />
              </div>
            </section>
          );
        })}
      </main>
    </article>
  );
}

function PatientProfileSearchSuspense() {
  return (
    <article className="screen-loader-wrapper flex-center-children-horizontally fixed-header-space-position z-index-1">
      <main className="padding-medium max-width-588px inherit-parent-width ">
        {[...Array(10).keys()].map((index) => {
          return (
            <section className="flex-center-children-vertically " key={index}>
              <div className="shimmer padding-larger margin-default border-radius-100-percentage" />
              <div className="width-80percentage">
                <div className="shimmer padding-small margin-default border-radius-default" />
                <div className="shimmer padding-small margin-default border-radius-default" />
              </div>
            </section>
          );
        })}
      </main>
    </article>
  );
}
