import React, { useEffect, useState } from "react";
import "./index.css";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation
} from "react-router-dom";
import LogRocket from "logrocket";
import {
  useNotificationListener,
  // useRequestSubscriber,
  useClinicStaffsListener,
  useConnectionsListener,
  useFollowingPatientsListener,
  usePinnedPatientsListener
} from "./services/database";
import { connect } from "react-redux";
import { useAuthSubscriber } from "./services/authentication";
import SnackBar from "./front-end-global-components/components/SnackBar/SnackBar";
import Home from "./Screens/Home/Home";
import Login from "./Screens/Login/Login";
import SignUp from "./Screens/SignUp/SignUp";
import ProfileCompletion from "./Screens/Profile/ProfileCompletion/ProfileCompletion";
import EditProfile from "./Screens/Profile/EditProfile/EditProfile";
import ViewDocument from "./Screens/ViewDocument/ViewDocument";
import PatientProfile from "./Screens/PatientProfile/PatientProfile";
import AddPrescription from "./Screens/AddPrescription/AddPrescription";
import ViewSymptom from "./Screens/ViewSymptom/ViewSymptom";
import Query from "./Screens/Query/Query";
import QuerySuccess from "./Screens/Query/QuerySuccess";
// eslint-disable-next-line
import perf from "./services/performance";
import Vitals from "./Screens/Vitals/Vitals";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import reloadUpdate from "../src/assets/icons/reload_update.svg";
import Forms from "./Screens/Forms/Forms";
import AbdmAuthentication from "./Screens/ABDM/AbdmAuthentication";
import InputBox from "./front-end-global-components/components/InputBox/InputBox";
import Button from "./front-end-global-components/components/Button/Button";
import Modal from "./front-end-global-components/components/Modal/Modal";
import { isValidObject } from "./front-end-global-components/services/validators";
import { validProfileObject } from "./services/utils";
import { editProfileRequest } from "./redux/profile/actions";
import { logrocketInit } from "./utils/constants";
import { useRemoteConfig } from "./services/remoteConfig";
import { logUserData } from "./services/analytics";
// import { logout } from "./redux/authentication/actions";
// import errorBoundaryFallBack from "./assets/icons/permissionDeniedFallBack.svg";
import { onMessage } from "firebase/messaging";
import { messaging } from "./services/firebase";
function App(props) {
  const [error, setError] = useState(null);
  const [isAuthed, setIsAuthed] = useState(false);
  const [remoteConfig, setRemoteConfig] = useState(null);
  const [showAddUserNameModal, setShowAddUserNameModal] = useState(false);
  const [addUserNameFormData, setAddUserNameFormData] = useState({
    userName: "Dr."
  });
  const location = useLocation();
  const navigate = useNavigate();

  useAuthSubscriber((isAuthed) => {
    setIsAuthed(isAuthed);
  }, isAuthed);

  //remote config listener custom hook
  useRemoteConfig((remoteConfig) => {
    setRemoteConfig(remoteConfig);
  }, isAuthed);

  useClinicStaffsListener({
    uid: props.auth.data.uid,
    phoneNumber: props.auth.data.phoneNumber,
    isAuthed: isAuthed
  });

  useConnectionsListener({
    uid: props.profile?.data && Object.keys(props.profile?.data)?.[0],
    phoneNumber: props.auth.data.phoneNumber,
    isAuthed: isAuthed
  });

  usePinnedPatientsListener({
    uid: props.profile?.data && Object.keys(props.profile?.data)?.[0],
    currentClinic: props.clinics?.currentClinic,
    isAuthed: isAuthed
  });

  useFollowingPatientsListener({
    uid: props.profile?.data && Object.keys(props.profile?.data)?.[0],
    currentClinic: props.clinics?.currentClinic,
    isAuthed: isAuthed
  });

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       for (let index = 0; index < 70; index++) {
  //         await createNotifications(
  //           props.profile?.data && Object.keys(props.profile?.data)?.[0],
  //           index
  //         );
  //       }
  //     } catch (error) {
  //       // Handle any errors
  //       console.error("Error:", error);
  //     }
  //   };

  //   fetchData(); // Call the async function
  // }, []);

  useNotificationListener({
    profileId: props.profile?.data && Object.keys(props.profile?.data)[0],
    isAuthed: isAuthed
  });

  onMessage(messaging, (payload) => {
    console.log(payload);
  });

  useEffect(() => {
    if (props.status.message) {
      setError(props.status.message);
    } else {
      setError(null);
    }
  }, [props.status]);

  //for log rocket init
  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" &&
      window.location.hostname !== "localhost"
    ) {
      const release =
        typeof process.env.REACT_APP_BUILD_NUM === "string"
          ? { release: process.env.REACT_APP_BUILD_NUM }
          : {};
      if (logrocketInit.key) {
        LogRocket.init(logrocketInit.key, release);
      }
    }
  }, []);

  useEffect(() => {
    if (!props.auth.data.uid) {
      return;
    }
    if (!isValidObject(props.profile.data)) {
      return;
    }
    if (
      isAuthed &&
      (props.profile.data[Object.keys(props.profile.data)[0]].fullName ===
        undefined ||
        props.profile.data[Object.keys(props.profile.data)[0]].fullName ===
          null ||
        props.profile.data[Object.keys(props.profile.data)[0]].fullName === "")
    ) {
      setShowAddUserNameModal(true);
    } else {
      setShowAddUserNameModal(false);
    }
  }, [props.profile.data, props.auth.data, isAuthed]);

  //useEffect for relaying user data to analytics
  useEffect(() => {
    if (props.auth.data.phoneNumber && props.auth.data.uid) {
      logUserData(props.auth.data.phoneNumber, props.auth.data.uid);
    }
  }, [props.auth.data.phoneNumber, props.auth.data.uid]);

  return (
    <React.Fragment>
      <TransitionGroup className={"inherit-parent-height"}>
        <CSSTransition timeout={300} classNames="my" key={location.key}>
          <Routes>
            <Route
              exact
              path="/login"
              element={
                <PublicRoute
                  accessToken={props.auth.data.accessToken}
                  profileData={props.profile.data}
                >
                  <Login navigate={navigate} />
                </PublicRoute>
              }
            />

            <Route
              exact
              path="/signup"
              element={
                <PublicRoute
                  accessToken={props.auth.data.accessToken}
                  profileData={props.profile.data}
                >
                  <SignUp navigate={navigate} />
                </PublicRoute>
              }
            />
            <Route
              exact
              path="/"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  profileData={props?.profile?.data}
                  remoteConfig={remoteConfig}
                >
                  <Home
                    isNamePresent={props.isNamePresent}
                    remoteConfig={remoteConfig}
                    navigate={navigate}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/i"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  profileData={props?.profile?.data}
                  remoteConfig={remoteConfig}
                >
                  <Home
                    isNamePresent={props.isNamePresent}
                    navigate={navigate}
                  />
                </ProtectedRoute>
              }
            />

            <Route
              exact
              path="/addvitals"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  profileData={props?.profile?.data}
                  remoteConfig={remoteConfig}
                >
                  <Vitals navigate={navigate} />
                </ProtectedRoute>
              }
            />

            <Route
              exact
              path="/profilecompletion"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  profileData={props?.profile?.data}
                  remoteConfig={remoteConfig}
                >
                  <ProfileCompletion navigate={navigate} />
                </ProtectedRoute>
              }
            />

            <Route
              exact
              path="/editprofile"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  profileData={props?.profile?.data}
                  remoteConfig={remoteConfig}
                >
                  <EditProfile navigate={navigate} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/reports/:documentID"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  profileData={props?.profile?.data}
                  remoteConfig={remoteConfig}
                >
                  <ViewDocument
                    navigate={navigate}
                    remoteConfig={remoteConfig}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/prescriptions/:documentID"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  profileData={props?.profile?.data}
                  remoteConfig={remoteConfig}
                >
                  <ViewDocument
                    navigate={navigate}
                    remoteConfig={remoteConfig}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/consultation/:documentID"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  profileData={props?.profile?.data}
                  remoteConfig={remoteConfig}
                >
                  <ViewDocument
                    navigate={navigate}
                    remoteConfig={remoteConfig}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/vital/:documentID"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  profileData={props?.profile?.data}
                  remoteConfig={remoteConfig}
                >
                  <ViewDocument
                    navigate={navigate}
                    remoteConfig={remoteConfig}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/others/:documentID"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  profileData={props?.profile?.data}
                  remoteConfig={remoteConfig}
                >
                  <ViewDocument
                    navigate={navigate}
                    remoteConfig={remoteConfig}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/vaccine/:documentID"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  profileData={props?.profile?.data}
                  remoteConfig={remoteConfig}
                >
                  <ViewDocument
                    navigate={navigate}
                    remoteConfig={remoteConfig}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admissions/:documentID"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  profileData={props?.profile?.data}
                  remoteConfig={remoteConfig}
                >
                  <ViewDocument
                    navigate={navigate}
                    remoteConfig={remoteConfig}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/symptoms/:documentId"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  profileData={props?.profile?.data}
                  remoteConfig={remoteConfig}
                >
                  <ViewSymptom navigate={navigate} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/addPrescription"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  profileData={props?.profile?.data}
                  remoteConfig={remoteConfig}
                >
                  <AddPrescription navigate={navigate} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/patientProfile/:patientId"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  profileData={props?.profile?.data}
                  remoteConfig={remoteConfig}
                >
                  <PatientProfile navigate={navigate} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/queries"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  profileData={props?.profile?.data}
                  remoteConfig={remoteConfig}
                >
                  <Query navigate={navigate} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/forms/:documentId"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  profileData={props?.profile?.data}
                  remoteConfig={remoteConfig}
                >
                  <Forms navigate={navigate} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/querysuccess"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  profileData={props?.profile?.data}
                  remoteConfig={remoteConfig}
                >
                  <QuerySuccess navigate={navigate} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/abdm/authentication"
              element={
                <ProtectedRoute
                  accessToken={props.auth.data.accessToken}
                  profileData={props?.profile?.data}
                  remoteConfig={remoteConfig}
                >
                  <AbdmAuthentication navigate={navigate} />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
      <SnackBar
        type={props.status.code === null ? "success" : "error"}
        message={error}
        status={props.status}
      />
      <div
        id="critical-update-card"
        className="display-none inherit-parent-height inherit-parent-width background-color-black-with-opacity-light position-absolute-center-self "
      >
        <footer className="position-absolute  inherit-parent-width bottom-0 padding-large">
          <div
            id="critical-update-card-contents-wrapper"
            className="background-color-white inherit-parent-width padding-large border-radius-default display-flex flex-direction-column flex-align-items-center "
          >
            <img
              src={reloadUpdate}
              alt="update"
              className=" margin-bottom-default"
            />
            <p className="font-family-gilroy-medium font-size-medium font-color-secondary text-align-center margin-bottom-default ">
              {" "}
              Critical update available{" "}
            </p>
            <p className="font-family-gilroy-regular font-size-medium font-color-secondary text-align-center margin-bottom-default ">
              New critical changes updated and ready to launch.
            </p>
            <button
              id="critical-update-confirm-button"
              className="inherit-parent-width padding-vertical-medium font-family-gilroy-bold border-radius-default font-size-medium cursor-pointer background-color-primary font-color-white box-shadow-none"
            >
              Okay
            </button>
          </div>
        </footer>
      </div>
      <Modal
        show={showAddUserNameModal}
        canIgnore={false}
        onClose={() => {
          setShowAddUserNameModal(!showAddUserNameModal);
        }}
        position={"position-fixed bottom-0 left-0 right-0"}
        width="inherit-parent-width"
        maxWidth={"max-width-500px"}
        background="false"
        boxShadow="false"
        borderRadius="false"
        height="height-fit-to-content"
      >
        <div
          className={`background-white padding-larger  border-radius-default box-shadow-default font-family-gilroy-regular font-color-secondary`}
        >
          <div className=" text-align-center font-family-gilroy-medium">
            Welcome! What is your name?
          </div>
          <form
            onChange={(event) => {
              setAddUserNameFormData({
                ...addUserNameFormData,
                [event.target.name]: event.target.value
              });
            }}
            onSubmit={(event) => {
              event.preventDefault();
              props.editProfileRequest(
                {
                  fullName: addUserNameFormData.userName
                },
                "fullName"
              );
              setShowAddUserNameModal(false);
            }}
          >
            <InputBox
              className="inherit-parent-width margin-top-larger"
              label="Your name"
              type="text"
              name="userName"
              removeResponsive={true}
              notes="Please enter name as per your official ID"
              value={addUserNameFormData.userName}
            />
            <Button
              data-cy="continueButton"
              boxShadow={false}
              disabled={addUserNameFormData.userName ? false : true}
              className="margin-top-larger font-family-gilroy-medium"
              variant="primary"
              text="Continue"
              type="submit"
            />
          </form>
        </div>
      </Modal>
    </React.Fragment>
  );
}

const ProtectedRoute = ({
  accessToken,
  children,
  profileData,
  remoteConfig
}) => {
  if (accessToken === null || !validProfileObject(profileData)) {
    return <Navigate to="/login" />;
  }

  // else if (remoteConfig?.experimental === false) {
  //   return <UnAuthorizedFallBack />;
  // } else if (remoteConfig === null) {
  //   return <div></div>;
  // }
  else {
    return children;
  }
};

const PublicRoute = ({ accessToken, children, profileData }) => {
  if (accessToken === null || !validProfileObject(profileData)) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

const mapStateToProps = function (state) {
  return {
    status: state.status,
    auth: state.auth,
    profile: state.profile,
    clinics: state.clinics
  };
};

const mapDispatchToProps = function () {
  return {
    editProfileRequest: (data, currentField) =>
      editProfileRequest(data, currentField)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);

// const UnAuthorizedFallBack = () => {
//   return (
//     <main className="inherit-parent-height inherit-parent-width flex-place-children-page-center padding-left-larger padding-right-larger">
//       <div>
//         <div className="padding-bottom-larger flex-center-children-horizontally">
//           <img
//             src={errorBoundaryFallBack}
//             className="error-fallback-image-height"
//             alt="error-fallback"
//             width="auto"
//           />
//         </div>

//         <div>
//           <div
//             style={{ fontSize: 24 }}
//             className="font-weight-normal font-family-gilroy-bold text-align-center font-color-secondary font-size-larger padding-bottom-large"
//           >
//             Access Denied !
//           </div>
//           <div className="text-align-center font-size-larger">
//             You are not authorized for further access.
//           </div>
//           <div className="text-align-center font-size-larger">
//             Please check back after sometime.
//           </div>
//         </div>
//         <div className=" text-align-center padding-top-larger">
//           <Button
//             boxShadow={false}
//             text="Take me back"
//             onClick={() => {
//               logout();
//             }}
//           />
//         </div>
//       </div>
//     </main>
//   );
// };
