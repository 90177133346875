import { all, put, takeEvery } from "@redux-saga/core/effects";
import store from "../store/store";
import { getPatientDocuments, getDataTransfer } from "../../services/database";
import { retryDataTransfer } from "../../services/api";
import { isValidArray, isValidObject } from "../../services/validators";
import { setErrorStatus, setSuccessStatus } from "../status/actions";
import { getDocumentsData, getDataTransferDetails } from "./actions";

export const documentsActionTypes = {
  GET_DOCUMENTS_DATA: "GET_DOCUMENTS_DATA",
  SET_SELECTED_DOCUMENT: "SET_SELECTED_DOCUMENT",
  GET_UPDATED_DOCUMENTS: "GET_UPDATED_DOCUMENTS",
  ADD_DOCUMENT_FILTER: "ADD_DOCUMENT_FILTER",
  REMOVE_DOCUMENT_FILTER: "REMOVE_DOCUMENT_FILTER",
  GET_NEXT_SET_OF_DOCUMENTS: "GET_NEXT_SET_OF_DOCUMENTS",
  GET_DATA_TRANSFER_DETAILS: "GET_DATA_TRANSFER_DETAILS",
  RETRY_DATA_TRANSFER: "RETRY_DATA_TRANSFER"
};

function* selectCurrentDocumentWorker(action) {
  try {
    yield put({
      type: "SET_CURRENT_DOCUMENT",
      payload: {
        currentDocument: action.payload.currentDocument
      }
    });
  } catch (error) {
    setErrorStatus(error);
  }
}

function* getDocumentsDataWorker(action) {
  try {
    yield;
    const patientId = action.payload.patientId;
    const clinicId =
      store.getState().clinics?.data?.[store.getState().clinics.currentClinic]
        .connectionId;
    if (patientId && clinicId) {
      const result = yield getPatientDocuments(patientId, clinicId);

      let documents = {};
      isValidArray(result) &&
        result.forEach((data) => {
          documents = { ...documents, [data.documentId]: data };
        });

      if (isValidObject(documents)) {
        yield put({
          type: "SET_DOCUMENTS_DATA",
          payload: {
            documents: { ...store.getState().documents.data, ...documents }
          }
        });
      }
    }
  } catch (error) {
    setErrorStatus(error);
  }
}

function* addDocumentFilterWorker(action) {
  try {
    let filters = store.getState().documents.filters;
    switch (action.payload.type) {
      //Filter by document type
      case "document":
        //previous document type filter states
        let documentsType = filters.documentType;
        //checks document type filter already exist and remove if exists
        if (action.payload.filterValue === "allDocuments") {
          documentsType = []
        } else if (documentsType.includes(action.payload.filterValue)) {
          const index = documentsType.indexOf(action.payload.filterValue);
          if (index > -1) {
            documentsType.splice(index, 1);
          }
        } else {
          documentsType.push(action.payload.filterValue);
        }
        //reducer dispatch to store the documentFilter
        yield put({
          type: "FILTER_BY_DOCUMENTS",
          payload: {
            documentsType: documentsType
          }
        });
        return;

      case "doctor":
        //previous document type filter states
        let doctorsName = filters.doctors;
        //checks document type filter already exist and remove if exists
        if (doctorsName.includes(action.payload.filterValue)) {
          const index = doctorsName.indexOf(action.payload.filterValue);
          if (index > -1) {
            doctorsName.splice(index, 1);
          }
        } else {
          doctorsName.push(action.payload.filterValue);
        }
        yield put({
          type: "FILTER_BY_DOCTORS",
          payload: {
            doctorsName: doctorsName
          }
        });
        return;

      case "clinic":
        //previous document type filter states
        let clinicsName = filters.clinics;
        //checks document type filter already exist and remove if exists
        if (clinicsName.includes(action.payload.filterValue)) {
          const index = clinicsName.indexOf(action.payload.filterValue);
          if (index > -1) {
            clinicsName.splice(index, 1);
          }
        } else {
          clinicsName.push(action.payload.filterValue);
        }
        yield put({
          type: "FILTER_BY_CLINICS",
          payload: {
            clinicsName: clinicsName
          }
        });
        return;

      default:
        return;
    }
  } catch (error) {
    setErrorStatus(error);
  }
}

function* removeDocumentFilterWorker(action) {
  try {
    switch (action.payload.clear) {
      case "doctorAndClinic":
        yield put({
          type: "FILTER_BY_DOCTORS",
          payload: {
            doctorsName: []
          }
        });
        yield put({
          type: "FILTER_BY_CLINICS",
          payload: {
            clinicsName: []
          }
        });
        return;
      default:
        break;
    }
  } catch (error) {
    setErrorStatus(error);
  }
}

function* getDataTransferDetailsWorker(action) {
  try {
    const clinicId =
      store.getState().clinics?.data?.[store.getState().clinics.currentClinic]
        .connectionId;

    const result = yield getDataTransfer(action.payload.patientId, clinicId);

    yield put({
      type: "SET_DATA_TRANSFER_DETAILS",
      payload: {
        [action.payload.patientId]: result
      }
    });
  } catch (error) {
    setErrorStatus(error);
  }
}

export function* retryDataTransferWorker(action) {
  try {
    yield setDocumentLoading(true);
    const getSubscriptionId = () => {
      if (
        store.getState().abdmPatients.subscriptionRequest?.[
        action.payload.patientId
        ]
      ) {
        const consentRequest =
          store.getState().abdmPatients.consentRequest?.[
          action.payload.patientId
          ];
        if (
          Object.values(consentRequest).every(
            (data) => typeof data.subscriptionId === "string"
          )
        ) {
          return Object.values(consentRequest)[0].subscriptionId;
        } else if (
          Object.values(consentRequest).every(
            (data) => typeof data.subscriptionId !== "string"
          )
        ) {
          return;
        } else {
          throw new Error("Invalid subscription consents");
        }
      }
    };

    const subscriptionId = yield getSubscriptionId();

    const clinicId = store.getState().clinics.currentClinic;
    const accessToken = store.getState().auth.data.accessToken;
    const consentId = action.payload?.consentId;
    const consent = Object.values(
      store.getState().abdmPatients.consentRequest[action.payload.patientId]
    ).find((data) => data.status === "GRANTED");

    const response = yield retryDataTransfer(accessToken, {
      clinicId: clinicId,
      ...(subscriptionId
        ? { subscriptionId: subscriptionId }
        : { consentRequestId: consent.consentRequestId }),
      // consentRequestId: consent.consentRequestId,
      patientId: action.payload.patientId,
      ...(consentId ? { consentId: consentId } : {})
    });
    if (response.success) {
      setSuccessStatus("Retrying...");
      getDataTransferDetails(action.payload.patientId);
      getDocumentsData(action.payload.patientId);
    }

    yield setDocumentLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setDocumentLoading(false);
  }
}

export default function* documentsWatcher() {
  yield all([
    takeEvery("GET_DOCUMENTS_DATA", getDocumentsDataWorker),
    takeEvery("ADD_DOCUMENT_FILTER", addDocumentFilterWorker),
    takeEvery("REMOVE_DOCUMENT_FILTER", removeDocumentFilterWorker),
    takeEvery("SET_SELECTED_DOCUMENT", selectCurrentDocumentWorker),
    takeEvery("GET_DATA_TRANSFER_DETAILS", getDataTransferDetailsWorker),
    takeEvery("RETRY_DATA_TRANSFER", retryDataTransferWorker)
  ]);
}

function* setDocumentLoading(bool) {
  yield put({
    type: "SET_DOCUMENTS_LOADING",
    payload: {
      loading: bool
    }
  });
}
