import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Header from "../../front-end-global-components/components/Header/Header";
import PDFRenderer from "../../front-end-global-components/components/PDFRenderer/PDFRenderer";

import "./AddPrescription.css";
import { isValidObject } from "../../services/validators";
import { getPrescriptionTemplateDownloadUrl } from "../../redux/downloads/actions";
// import { addPrescription } from "../../redux/documents/actions";

function AddPrescription(props) {
  const [pdfArrayBuffer, setPdfArrayBuffer] = useState({});
  const [currentDoc] = useState("base");
  useEffect(() => {
    if (
      typeof props.clinics.currentClinic === "string" &&
      !(
        isValidObject(props.downloads.data) &&
        isValidObject(props.downloads.data.templates) &&
        typeof props.downloads.data.templates[props.clinics.currentClinic] ===
          "string"
      )
    ) {
      props.getPrescriptionTemplateDownloadUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.downloads.data, props.clinics.currentClinic]);

  const shouldShowSuspense = () => {
    if (
      props.downloads.loading === false &&
      typeof props.clinics.currentClinic === "string" &&
      isValidObject(props.downloads.data) &&
      isValidObject(props.downloads.data.templates) &&
      typeof props.downloads.data.templates[props.clinics.currentClinic] ===
        "string"
    ) {
      return false;
    } else {
      return true;
    }
  };
  const getPdfData = () => {
    if (!!pdfArrayBuffer.base) {
      return;
    }
    if (
      isValidObject(props.documents.data) &&
      isValidObject(props.downloads.data.templates) &&
      typeof props.clinics.currentClinic === "string" &&
      typeof props.downloads.data.templates[props.clinics.currentClinic] ===
        "string"
    ) {
      fetch(props.downloads.data.templates[props.clinics.currentClinic]).then(
        async (res) => {
          setPdfArrayBuffer({
            ...pdfArrayBuffer,
            base: await res.arrayBuffer()
          });
        }
      );
    }
  };
  useEffect(() => {
    if (
      isValidObject(props.downloads.data?.templates) &&
      typeof props.clinics.currentClinic === "string" &&
      typeof props.downloads.data.templates?.[props.clinics.currentClinic] ===
        "string" &&
      !Object.keys(pdfArrayBuffer).includes("base")
    ) {
      getPdfData();
    }
    // eslint-disable-next-line
  }, [props.downloads.data, props.clinics.currentClinic]);
  return (
    <div className="inherit-parent-height">
      <Header
        title={"Add prescription"}
        backButtonOnClick={() => props.navigate(-1)}
        supportIconOnClick={() => {
          props.navigate("/queries");
        }}
      />
      <article className=" remaining-body-height overflow-x-scroll hide-scroll-bar flex-direction-column overflow-auto">
        {shouldShowSuspense() === true ? (
          <TemplateSuspense />
        ) : (
          <section className="flex-grow-1 hide-scroll-bar inherit-parent-height inherit-parent-width background-white overflow-y-auto">
            {/* <PDFRenderer
                readOnly={false}
                pdfUrl={
                  props.downloads.data.templates[props.clinics.currentClinic]
                }
                isPrescription={true}
                saveWithBackground={true}
                loading={props.downloads.loading || props.documents.loading}
                addPrescription={props.addPrescription}
              /> */}
            <PDFRenderer
              readOnly={false}
              pdfArrayBuffer={
                pdfArrayBuffer[currentDoc] ? pdfArrayBuffer[currentDoc] : null
              }
              isPrescription={true}
              saveWithBackground={true}
              loading={props.downloads.loading || props.documents.loading}
              addPrescription={props.addPrescription}
            />
          </section>
        )}
      </article>
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    documents: state.documents,
    downloads: state.downloads,
    clinics: state.clinics
  };
};

const mapDispatchToProps = function () {
  return {
    // addPrescription: (prescriptionDataUrl, history) =>
    //   addPrescription(prescriptionDataUrl, history),
    getPrescriptionTemplateDownloadUrl: () => {
      getPrescriptionTemplateDownloadUrl();
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPrescription);

function TemplateSuspense() {
  return (
    <article
      data-cy="template-suspense"
      className="screen-loader-wrapper inherit-parent-width  flex-center-children-horizontally"
    >
      <main className="inherit-parent-width padding-medium ">
        <section className="inherit-parent-width flex-place-children-page-center height-10vh">
          <div className="shimmer padding-larger margin-default border-radius-default width-80percentage" />
        </section>
        <section className="shimmer height-60vh border-radius-default" />
        <section className="inherit-parent-width flex-place-children-page-center height-10vh ">
          <div className="shimmer padding-larger margin-default border-radius-default width-80percentage" />
        </section>
      </main>
    </article>
  );
}
